var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._b({tag:"component",class:[
    'alcor-button',
    {
      flat: _vm.flat,
      alternative: _vm.alternative,
      access: _vm.access,
      orange: _vm.orange,
      danger: _vm.danger,
      outline: _vm.outline,
      bordered: _vm.bordered,
      transparent: _vm.transparent,
      iconOnly: _vm.iconOnly,
      iconOnlyAlt: _vm.iconOnlyAlt,
      round: _vm.round,
      big: _vm.big,
      compact: _vm.compact,
    },
  ],on:{"click":function($event){return _vm.$emit('click')}}},'component',_vm.$attrs,false),[_c('div',{staticClass:"inner"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }