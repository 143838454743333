import locale77427e78 from '../../locales/en.js'
import locale77fcde9e from '../../locales/ru.js'
import locale77264f76 from '../../locales/cn.js'
import locale77dac6c9 from '../../locales/ph.js'
import locale781e0db5 from '../../locales/ua.js'
import locale777d96b6 from '../../locales/it.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.js"},{"code":"ru","iso":"ru-RU","file":"ru.js"},{"code":"cn","iso":"zh_CN","file":"cn.js"},{"code":"ph","iso":"ph_PH","file":"ph.js"},{"code":"ua","iso":"ua_UA","file":"ua.js"},{"code":"it","iso":"it_IT","file":"it.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "~/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.js"},{"code":"ru","iso":"ru-RU","file":"ru.js"},{"code":"cn","iso":"zh_CN","file":"cn.js"},{"code":"ph","iso":"ph_PH","file":"ph.js"},{"code":"ua","iso":"ua_UA","file":"ua.js"},{"code":"it","iso":"it_IT","file":"it.js"}],
  localeCodes: ["en","ru","cn","ph","ua","it"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "o",
  15: "c",
  16: "a",
  17: "l",
  18: "e",
  19: "s",
  20: "/",
  21: "e",
  22: "n",
  23: ".",
  24: "j",
  25: "s",
  26: "\"",
  27: ",",
  28: "\"",
  29: "r",
  30: "u",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ":",
  36: "\"",
  37: ".",
  38: ".",
  39: "/",
  40: "l",
  41: "o",
  42: "c",
  43: "a",
  44: "l",
  45: "e",
  46: "s",
  47: "/",
  48: "r",
  49: "u",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: ",",
  55: "\"",
  56: "c",
  57: "n",
  58: ".",
  59: "j",
  60: "s",
  61: "\"",
  62: ":",
  63: "\"",
  64: ".",
  65: ".",
  66: "/",
  67: "l",
  68: "o",
  69: "c",
  70: "a",
  71: "l",
  72: "e",
  73: "s",
  74: "/",
  75: "c",
  76: "n",
  77: ".",
  78: "j",
  79: "s",
  80: "\"",
  81: ",",
  82: "\"",
  83: "p",
  84: "h",
  85: ".",
  86: "j",
  87: "s",
  88: "\"",
  89: ":",
  90: "\"",
  91: ".",
  92: ".",
  93: "/",
  94: "l",
  95: "o",
  96: "c",
  97: "a",
  98: "l",
  99: "e",
  100: "s",
  101: "/",
  102: "p",
  103: "h",
  104: ".",
  105: "j",
  106: "s",
  107: "\"",
  108: ",",
  109: "\"",
  110: "u",
  111: "a",
  112: ".",
  113: "j",
  114: "s",
  115: "\"",
  116: ":",
  117: "\"",
  118: ".",
  119: ".",
  120: "/",
  121: "l",
  122: "o",
  123: "c",
  124: "a",
  125: "l",
  126: "e",
  127: "s",
  128: "/",
  129: "u",
  130: "a",
  131: ".",
  132: "j",
  133: "s",
  134: "\"",
  135: ",",
  136: "\"",
  137: "i",
  138: "t",
  139: ".",
  140: "j",
  141: "s",
  142: "\"",
  143: ":",
  144: "\"",
  145: ".",
  146: ".",
  147: "/",
  148: "l",
  149: "o",
  150: "c",
  151: "a",
  152: "l",
  153: "e",
  154: "s",
  155: "/",
  156: "i",
  157: "t",
  158: ".",
  159: "j",
  160: "s",
  161: "\"",
  162: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'ru.js': () => Promise.resolve(locale77fcde9e),
  'cn.js': () => Promise.resolve(locale77264f76),
  'ph.js': () => Promise.resolve(locale77dac6c9),
  'ua.js': () => Promise.resolve(locale781e0db5),
  'it.js': () => Promise.resolve(locale777d96b6),
}
