export default {
  META_DESCRIPTION:
    'Alcor 交易平台是 DeFi 多功能交易平台！可获取收益的流动性挖矿池 | 限价交易 | NFT 市场等等!',
  META_TITLE: '基于 EOS 的"去信任" DEX.',
  META_NFT_MARKET_TITLE: 'Alcor NFT 市场 | WAX 链上的"去信任" NFT 市场',
  META_NFT_MARKET_DESCRIPTION: 'Atomic, 免费, NFT 市场.',
  HERO_DESCRIPTION: 'Alcor 是模块化的多链 DeFi 平台，提供一键式解决方案.',
  HERO_TITLE: '多功能全方位 DEX 交易平台',
  START_TRAIDING_BTN: '开始交易',
  READ_DOCS_BTN: '阅读详情',
  ONCHAIN_TOOLS_TITLE: '链上工具',
  'AMM Swap': 'AMM 交易',
  'Spot Trading': '现货交易',
  Earn: '赚取',
  NFT: 'NFT',
  'With Pair Creation': '创建交易对',
  'Limit & Market': '限价 & 市价',
  'Providing Liquidity': '提供流动性',
  'Multiple Protocols': '多协议',
  RECOMMENDED_MARKETS_TITLE: '推荐市场',
  SUPPORTED_UPCOMING_CHAINS_TITLE: '已支持和即将支持的链',
  BUILDINGGLOBALCOMMUNITY: '创建全球社区.',
  LEARNMORE: '了解有关 Alcor 的更多信息',
  TRAIDING_TALKS: '官方支持与在线解决交易问题',
  REVIEW_NEWS: '了解最新报道和消息',
  CODE_CONTIBUTE: '代码与贡献',
  ANOUNCEMENT: '公告',
  PARTNERS_PROVIDERS: '合作伙伴 & API 供应商',
  'and more...': '更多...',
  Fav: '收藏',
  All: '全部',
  USDT: 'USDT',
  'Cross-Chain': '跨链',
  'Search market': '搜索市场',
  'Open new market': '创建新市场',
  Pair: '交易对',
  'Last price': '最新价格',
  '24H Vol.': '24小时交易额.',
  '24H': '24小时',
  '7D Volume': '7日交易额',
  '7D Change': '7日变化',
  'The OTC contract was changed(updated)!': 'OTC合约变更（更新）!',
  Orders: '订单',
  Order: '订单',
  Owner: '账户',
  Sell: '买入',
  Buy: '卖出',
  'Old orders can be found and canceled(recommended) here:':
    '历史订单可以在这里找到和取消（推荐）:',
  token: '代币',
  ID: 'ID',
  Price: '价格',
  History: '历史记录',
  'Rules & Information': '规则 & 详情',
  RULES_INFO_1: `使用 TOKENSWAP，您可以任意兑换任何双方都是 EOS.IO 类型的代币，这意味着，只要是应符合 eosio.token 合约标准的代币都可以任意交易，无需第三方支持！`,
  Properties: '特性:',
  RULES_INFO_2:
    '订单记录和兑换的所有逻辑都由合约处理，无需任何额外的中心化解决方案.',
  RULES_INFO_3: '合约自动执行兑换，完全去中心化.',
  RULES_INFO_4: '此应用程序仅使用 EOS 公共节点即可运行，无需集中式后端.',
  RULES_INFO_5:
    '对于大额交易，将对每个交易对的两个代币各收取 0.25% 的佣金。对于小额交易，将不收取任何佣金.',
  'Roadmap:': '路线图:',
  RULES_INFO_6: '应用程序的整体全新设计.',
  RULES_INFO_7: '计划首先将开源 Web 应用程序。然后再开源合约部分.',
  RULES_INFO_8: '已开发附加服务以提升用户体验，便于搜索、排序和处理订单.',
  'Audit:': '审计:',
  'Exchange contract:': '交易合约:',
  'Comission account:': '佣金账号:',
  RULES_INFO_9:
    '各个项目的负责人朋友，您有任何的合作提案，都可通过电报联系我们!',
  RULES_INFO_10: '去中心化的基于 EOS 的交易所.',
  'created by': '创建者',
  'not found or finished': '无结果或未完成',
  'Connect Wallet': '连接钱包',
  NewOrder: '新订单',
  Authors: '作者',
  Categories: '类别',
  'Create NFT token': '创建 NFT',
  'NFT Wallet': 'NFT 钱包',
  NFT_ALERT: `任何人都可以创建简单资产 NFT 并自由选择名称和图像等属性，包括现有 NFT 的伪造版本或被盗的版本。
在购买 NFT 之前，请务必自行研究收藏品并仔细检查收藏名称，以确保您购买的是正版 NFT.`,
  'Immutable data:': '不可变数据:',
  Category: '类别',
  'Hide small balances': '隐藏小额资产',
  'Search name or paste address': '搜索名称或粘贴地址',
  'Portfolio value': '投资组合价值',
  'Active positions': '活跃交易池',
  'Available funds': '可用资金',
  'Staking rewards': '质押激励',
  'LP rewards': 'LP 激励',
  Tokens: '代币',
  "NFT's": "NFT's",
  'Open Orders': '未结订单',
  'Liquidity Pools': '流动交易池',
  Resources: '资源',
  Asset: '资产',
  'Hide small balancess': '隐藏小额资产',
  Total: '总计',
  Available: '可用',
  'In Order': '订单中',
  'WAX Value': 'WAX 价值',
  Actions: '操作',
  Deposit: '充值',
  Withdraw: '提取',
  Pools: '交易池',
  Trade: '交易',
  Swap: '兑换',
  liquidity: '流动性',
  Liquidity: '流动性',
  Volume: '交易额',
  '7D': '7日',
  '30D': '30日',
  '300D': '300日',
  'Price Impact': '价格变化',
  Rate: '率',
  'Minimum Received': '最低可收到数量',
  Slippage: '滑点',
  'Liquidity Source Fee': '流动性分红',
  'Earn On Liquidity': '流动性收益',
  'Buy (Estimated)': '买入 (预计）',
  'Transaction Setting': '交易设置',
  Auto: '自动',
  'Create pool': '创建交易池',
  'Liquidity Positions': '流动性交易池',
  'Pool Share': '交易池分红',
  'Earning (Fees)': '收益 (手续费)',
  'Hide other pairs': '隐藏其他交易对',
  Orderbook: '订单薄',
  'Depth Chart': '深度图',
  Markets: '市场',
  'Times & Sales': '时间 & 交易量',
  'Open orders': '未结订单',
  'Trade History': '交易记录',
  Funds: '资金',
  'Limit trade': '限价交易',
  'Market trade': '市价交易',
  'Your order to:': '您的订单:',
  'At a price of:': '成交价:',
  'Will be': '将',
  'do you wish to proceed?': '继续?',
  Yes: '是',
  No: '否',
  'Filter by token': '筛选代币',
  'Chart Settings': '图表设置',
  'Chart Preview': '图表预览',
  'Header Settings': '标题设置',
  'Chart Orders': '图表订单',
  SETTING_MODAL_TEX1: `支持您直接从图表中移动或取消订单，只需拖动 lavel 即可移动订单或单击“X”按钮取消订单`,
  'Reset to Default': '重置为默认',
  'Change 24H': '变为 24小时',
  'Volume 24H': '24小时交易额',
  '24H High': '24小时最高价',
  '24H Low': '24小时最低价',
  'Volume 24H USD': ' 24小时交易额(USD)',
  'Weekly Volume (WAX, USD)': '周交易额 (WAX, USD)',
  'Show open orders': '展示未结订单',
  'Show labels': '展示标签',
  'Chart order interactivity': '订单交互图表',
  'Chart Executions': '执行图表',
  'Show trade executions': '展示交易执行',
  'Show trade execution amount': '展示 交易 执行 数量',
  TOTAL: '总计',
  AMOUNT: '数量',
  PRICE: '价格',
  '24H USD': '24小时 USD',
  'Weekly Volume (WAX / USD)': '周交易额 (WAX / USD)',
  'All Time High/Low:': '历史最高/最低:',
  Amount: '数量',
  Balance: '余额',
  'Transfer to': '转账至',
  WITHDRAW_POPUP_MESSAGE:
    '在转账至交易所之前，您必须使用 BOS IBC 转账功能将其提取至原来的链!',
  Transfer: '转账',
  to: '至',
  Wrapped: 'Wrapped',
  'Pair(a-z)': '交易对(a-z)',
  'Vol 24h': '24小时交易额',
  SWAP: '兑换',
  'Buy at best price': '以最佳价格购买',
  'Buy more order slots': '购买更多订单通道',
  'Sell all items for': '出售所有道具',
  Author: '作者',
  "Select NFT's": '选择 NFT 的',
  NEW_ORDER_MESSAGE: '支持您以固定价格单次出售一个或多个 NFT',
  "Filter NFT's": "筛选 NFT's",
  'Fee Rate': '费率',
  'Maker Fee': '挂单手续费',
  'Taker Fee': '吃单手续费',
  Fee: '手续费',
  'balance:': '余额:',
  Time: '时间',
  'No open orders': '无未结订单',
  Type: '类型',
  Label: '标签',
  Cancel: '取消',
  'Cancel order': '取消订单',
  'Buy for': '购买',
  'Go to main page': '返回主页',
  Manage: '管理',
  Side: '边',
  Date: '日期',
  Bid: '出价',
  Ask: '叫价',
  Action: '操作',
  'Current Orders': '目前订单',
  'Total Quote': '总报价',
  'Total Base': '总基数',
  Earning: '收益',
  'Earning WAX Value': 'WAX 收益价值',
  fav: '收藏',
  all: '全部',
  'My Deposits': '我的充值',
  'All Type': '所有类型',
  'Liquidity mining': '流动性挖矿',
  Staking: '质押',
  Partners: '合作伙伴',
  'System token or USDT is recommended': '推荐系统通证或 USDT ',
  'Auto select': '自动选择',
  'Select token for new market': '选择代币以创建新市场',
  Manually: '手动',
  'Token symbol': '代币符号',
  'Token contract': '代币合约',
  'Total Sum': '总和',
  'Total Amount': '总数',
  Token: '代币',
  Quote: '报价',
  Seller: '卖家',
  Buyer: '买家',
  buy: '买',
  sell: '卖',
  'Slippage Tolerance': '最大滑点',
  'Token amount': '代币数量',
  Select: '选择',
  'Sell token': '卖出代币',
  'Voting For': '投票',
  'Proxied Accounts': '代理账户',
  Account: '账户',
  Proxies: '代理',
  Stake: '质押',
  'NET Stake Amount': 'NET质押数量',
  'CPU Stake Amount': 'CPU 质押数量',
  'NET Unstake Amount': 'NET解押数量',
  'CPU Unstake Amount': 'CPU解押数量',
  Unstake: '解押',
  'Crete new pool': '创建新交易池',
  'To create a pool, select the quote token and provide the initial liquidity ratio':
    '创建交易池，请选择报价代币并提供初始流动性比率',
  'You are the first liquidity provider. The ratio of tokens you add will set the price of this pool':
    '您是第一个流动性提供者。您添加的代币比例将决定该交易池的价格',
  'Backed token symbol (Automatically set recommended)':
    '支持的代币符号（推荐自动设置)',
  'Pool creation is': '创建的交易池为',
  FREE: '免费',
  Create: '创建',
  'Genesis and Voting Rewards': '创世和投票奖励',
  'Available Balance': '可用余额',
  'Refunding CPU': 'CPU 资源退回',
  'Refunding NET': 'NET 资源退回',
  'Your refund will be available at': '您的退款到账时间为',
  Validators: '验证器',
  Links: '链接',
  Location: '地点',
  Votes: '投票',
  Vote: '投票',
  'Total Votes': '总票数',
  'Rewards Per Day': '日收益',
  Status: '状态',
  Rank: '排名',
  Guilds: '公会',
  Send: '发送',
  'Estimated Get': '预计获得',
  'Select amounts': '选择数量',
  'Enter your EOS wallet address': '输入您的 EOS 钱包账户',
  Next: '下一步',
  'EOS ORDERS': 'EOS 订单',
  'avg completion time': '平均完成时间',
  '6 Minutes': '6分钟',
  '21% faster': '加速21%',
  'than other exchanges': '比其他交易所',
  'Exchanging 12 ETH to 7234 EOS': '12 ETH 兑换 7234 EOS',
  'Enter Address': '输入账户',
  'Send Coin': '发送代币',
  'Some description': '部分描述',
  'Exchange Status': '交易状态',
  "Don't have wallet yet?": '还没有钱包账户?',
  Second: '第二步',
  'Cross-Chain token swap': '跨链代币兑换',
  'No-fee, fully decentralized(Yes. no gateway)':
    '免费，完全去中心化（是的。没有网关)',
  'Account name': '账户名称',
  'DefiBox Swap': 'DefiBox Swap',
  Evodex: 'Evodex',
  'Swap tokens using Defibox': '使用Defibox进行代币兑换操作',
  'Search pool': '搜索交易池',
  'NFT creation': '创建 NFT ',
  'Alpha version': '内测版本',
  'This form allow you to create an NFT': '此表单支持您创建 NFT',
  'You will be the owner of the NFT immediately after creation and will be able to transfer or sell the NFT':
    '您将在创建后将立即成为 NFT 的所有者，并且能够转移或出售 NFT',
  'Name of the NFT': 'NFT名称',
  key: '密钥',
  value: '价值',
  'Mutable data': '可变数据',
  'Data that you can change(as the author of the NFT':
    '您可以更改的数据（作为 NFT 的作者）',
  'Create NFT': '创建NFT',
  Add: '添加',
  'Search NFT': '搜索NFT',
  'Create new order': '创建新订单',
  'Select token': '选择代币',
  'Potential SCAM token': '可能是假代币',
  'The DMT token has been hacked. Any movement of the token (including cancellation of the order) takes 99% of the transfer amount. For any questions, please contact the DMT team':
    'DMT 代币已被黑客入侵。代币的任何转移（包括取消订单）将自动扣除 99%转账金额。如有任何问题，请联系 DMT 团队',
  'Cross Chain transfers of BOSIBC tokens are temporary stopped! It is recommended to wait for the news before continuing trading':
    'BOSIBC 代币跨链转账暂停！建议等待官方通知再继续交易',
  BUY: '买',
  SELL: '卖',
  view: '浏览',
  'Only buy orders': '仅买单',
  'Only sell orders': '仅卖单',
  'Total orders': '全部订单',
  'Order slot limit': 'Order slot limit',
  "There is no NFT's yet": '目前没有 NFT ',
  'With Alcor you can trade any EOS.IO tokens for system EOS tokens, atomically, without the participation of third parties! The tokens should comply with the standard eosio.token of the contract':
    '使用 Alcor，您可以任意兑换任何双方都是 EOS.IO 类型的代币，这意味着，只要是应符合 eosio.token 合约标准的代币都可以任意交易，无需第三方支持！',
  Fully: '全部',
  onchain: '链上',
  'matching for limit/market trades': '限价/市价交易匹配',
  "All the logic of order storage and matching takes place in the contract's ram, without any additional centralized solutions":
    '所有订单记录和匹配的逻辑都存储在合约内存中，无需任何额外的中心化解决方案',
  'This application works without centralized back-end and uses only the public EOS node and public api serivices':
    '此应用程序仅使用EOS 公共节点和公开的 API 服务即可运行，无需集中式后端',
  'No commission at all': '无任何佣金',
  'for beta testing time': '内测中',
  FAQ: '常见问题',
  'You can add token, options': '您可以添加代币，选项',
  'Add icon to Eos token collection': '将图标添加到 Eos 代币集合',
  'All icon on alcor': 'alcor 上的所有图标',
  'name format': '名称格式',
  'If you cant do any of that': '如果你不能做到这一点',
  'send me': '发送给我',
  'Exchange contract': '交易合约',
  Technologies: '技术',
  'Hyperion by': 'Hyperion by',
  'The nice tool to get all actions history': '获取所有操作历史记录的优秀工具',
  'All trading graphs and deals history provided by hyperion':
    'hyperion 提供的所有交易图表和交易历史',
  'Light API': '轻量 API',
  'The nice tool to get token balances for users':
    '支持用户获取代币余额的优秀工具',
  'Hosted by': '主办方',
  'EOS Amsterdam': 'EOS Amsterdam',
  'Is very useful eosio chains explorer': '非常有用的 eosio 链资源管理器',
  'It uses for show all deals history and token contracts':
    '用于显示所有交易历史和代币合约',
  'Add Liquidity': '添加流动性',
  'Select pair': '选择交易对',
  'Clear All': '清空所有',
  'Select Pool': '选择交易池',
  'Select a pool type based on your preferred liquidity provider fee':
    '根据您首选的流动性提供者激励选择交易池类型',
  'Set Price Range': '设置价格区间',
  price: '价格',
  'Select a pool type based on your preferred liquidity provider fee.Your liquidity will only earn fees when the market price of the pair is within your range':
    '根据您首选的流动性提供者激励选择交易池类型。只有当交易对的市场价格达到您设置的价格范围内时，您才会赚取流动性激励',
  'Max Price': '最高价格',
  'Min Price': '最低价格',
  'Deposit Amounts': '充值数量',
  'Submit Test': '提交测试',
  'This is not a verification or request listing form':
    '这不是验证或请求上线的表单',
  'By selecting a token from the list below, you will be offered to sign a transaction that instantly opens a new market that will immediately be ready for placing orders':
    '通过从下面的列表中选择一个代币，您需要对一项交易进行链上签名，该交易会立即创建一个新市场，该市场将立刻支持用户交易',
  'This happens on smart contract, without the participation of third parties':
    '一切都发生在智能合约上，无需第三方参与',
  'You will buy': '您将购买',
  'RAM bytes for new market creation': '用于创建新市场的 RAM 字节',
  'BASE TOKEN': '基础代币',
  'QUOTE TOKEN': '叫价代币',
  'Unable to fetch user tokens.. use manually method':
    '无法获取用户代币.. 使用手动方法',
  'eosio.token betdicetoken ridlridlcoin eosiomeetone etc..':
    'eosio.token betdicetoken ridlridlcoin eosiomeetone etc..',
  'DICE TRYBE CAT EOS etc..': 'DICE TRYBE CAT EOS etc..',
  'Creation new market fee is': '创建新市场需要消耗',
  'Estimated Receive': '预计将收到',
  Invalid: '无效',
  'Insufficient Funds': '资金不足',
  "Oops! We can't seem to find the page you are looking for":
    '哎呀！我们似乎找不到您要查找的页面',
  'Back to Home': '返回首页',
  'Participate in Protocol': '加入协议',
  'Alcor DAO coming soon': 'Alcor DAO 即将上线',
  'Alcor Revenue Distribution': 'Alcor 收入分配',
  Governance: '治理',
  'Vote for Proposals': '对提案进行投票',
  'Release schedule': '发布时间表',
  Allocation: '分配',
  'The token is on sale soon, wait for updates on':
    '代币即将发售，敬请期待官方消息',
  Logout: '退出',
  Language: '语言',
  Layouts: '布局',
  'Layout Modules': '布局模块',
  'Return to Default Settings': '返回默认设置',
  'Current Price': '当前价格',
  Max: '最大',
  'Search name': '搜索名称',
  "Don't have any wallet yet": '还没有钱包账户',
  Get: '获取',
  'Network Resources': '网络资源',
  'You are low on CPU! Need more resources': 'CPU 不足，请质押更多资源',
  'You can unstake your': '您不能解押您的',
  'at any time': '任何时间',
  'Total Staked': '质押总量',
  "Sell NFT's": "卖出 NFT's",
  items: '道具',
  category: '类别',
  'Tokens are locked in the contract until the transaction is completed':
    '代币将被锁定在合约中，直到交易完成',
  'You can cancel the transaction at any time and return the tokens':
    '您可以随时取消交易并返还代币',
  'Pool creation fee is': '交易池创建费用为',
  'Provide liquidity': '填充流动性',
  'You can earn money on commissions from exchanges. Be sure to familiarize yourself with the principle of operation of liquidity pools':
    '您可以从交易中赚取佣金。一定要熟悉流动性交易池的运作原理',
  'Liquidity provider fee': '流动性提供者费用',
  'Pool size': '交易池大小',
  'Pool price': '交易池价格',
  'Pool token supply': '交易池代币供应量',
  'Liquidity token receive': '接收流动性代币',
  Provide: '填充',
  'Quick swap or make money on providing liquidity':
    '快速兑换或通过提供流动性赚钱',
  'The amount that you give': '你给的金额',
  Output: '提取',
  Input: '充入',
  'The amount that you will receive': '您将会收到的数量',
  'Price for current amount': '当前数量的价格',
  'No pools yet': '目前还没有交易池',
  'Withdraw liquidity': '提取流动性',
  'Backed token balance': '已支持的代币余额',
  'Will receive': '将会收到',
  'Position (LP-T)': '交易池 (LP-T)',
  'When the deposit becomes negative, it means that your liquidity has been profitable, and you have withdrawn the deposit and part of dividend':
    '当充值额变为负数时，表示您的流动性已经盈利，您已经提取了已充值代币和部分分红',
  'When you receive or transfer LP Tokens, the system will increase or decrease your deposit in proportion according to the total liquidity volume of liquidity pool':
    '当您收到或转移 LP 代币时，系统会根据流动性池的总流动性增加或减少您的已充值代币占比',
  'There may be a difference in statistics, for reference only':
    '统计数据可能存在差异，仅供参考',
  'Select Amount': '选择数量',
  'Search by name or contract': '搜索代币或代币合约',
  'Slippage Tolerance %': '最大滑点比例 %',
  'Orderbook Preview': '订单簿预览',
  'Orderbook Settings': '订单簿设置',
  'Orderbook Visualization': '订单薄可视化',
  'No asks': '无需说明',
  'Recommended tokens': '推荐代币',
  'Deposit Token': '充值代币',
  'WAX Account Name': 'WAX 账号名称',
  'NFT Details': 'NFT详情',
  Done: '已完成',
  "Before transferring to exchange, you have to withdraw it to it's original chain using BOS IBC Transfer button":
    '在转账至交易所之前，您必须使用 BOS IBC 转账功能将其提取至原来的链',
  'address..': '地址..',
  Receiver: '接收者',
  Memo: '备注o',
  'Withdraw amount': '提取数量',
  address: '地址',
  'Your token will be transferred to': '转账至',
  Promotions: '推荐',
  '1D Volume': '1D Volume',
  'Token promotion': '推荐代币',
  OTC: 'OTC',
  Wallet: '钱包',
  Docs: '文库',
  'Top Volume': '市值排名',
  'Top Gainers': '涨幅排名',
  'New Listings': '新上架币种',
  'No Data': 'No Data',
  'Beware of scammers!': 'Beware of scammers!',
  Staked: 'Staked',
  Pairs: 'Pairs',
  'Last Claim': 'Last Claim',
  'Orderbook settings': 'Orderbook settings',
  'Times and sales settings': 'Times and sales settings',
  'Amount in': 'Amount in',
  Preview: 'Preview',
  'Large trade thresholdA': 'Large trade threshold',
  'Time format': 'Time format',
  'choose time format': 'choose time format',
  Currency: 'Currency',
  Theme: 'Theme',
  'Favorite Top Line': 'Favorite Top Line',
  Chart: 'Chart',
  'Orderbook/Depth Chart': 'Orderbook/Depth Chart',
  'Times and Sales': 'Times and Sales',
  'Horizontal Order Form': 'Horizontal Order Form',
  'Vertical Order Form': 'Vertical Order Form',
  FullScreen: 'FullScreen',
  Advanced: 'Advanced',
  Classic: 'Classic',
  'Select Wallet': '选择钱包',
  'Wax Cloud Wallet': 'WAX 账户',
  From: '从',
  'Select Token': '选择代币',
  'ID/Name/Category/Author': 'ID/名称/类别/作者',
  'Trading Fees': 'Trading Fees',
  'Alcor has different fees based on the type of transaction you want to do':
    'Alcor has different fees based on the type of transaction you want to do',
  'When using the swap there is a .3% liquidity provider fee. This fee gets distributed between those providing liquidity in the pool':
    'When using the swap there is a .3% liquidity provider fee. This fee gets distributed between those providing liquidity in the pool',
  'Market Exchange': 'Market Exchange',
  'When placing a market or limit order there is a .2% exchange fee on only 4 markets. Certain markets will not have fees depending on the Alcor governance decision. This exchange fee goes towards the Alcor development fund':
    'When placing a market or limit order there is a .2% exchange fee on only 4 markets. Certain markets will not have fees depending on the Alcor governance decision. This exchange fee goes towards the Alcor development fund',
  'OTC Transaction': 'OTC Transaction',
  'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged This fee also goes towards the Alcor development fund':
    'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged This fee also goes towards the Alcor development fund',
  'Fees table': 'Fees table',
  'Market Maker': 'Market Maker',
  'Market Taker': 'Market Taker',
  Maker: 'Maker',
  Taker: 'Taker',
  'Token Pair': 'Token Pair',
  'Search name or address': 'Search name or address',
  'Full Name': 'Full Name',
  'Token Fundamentals': 'Token Fundamentals',
  Blockchain: 'Blockchain',
  'Ticker Name': 'Ticker Name',
  Socials: 'Socials',
  'Listing time': 'Listing time',
  Contract: 'Contract',
  Website: 'Website',
  'Source Code': 'Source Code',
  'Listing Tags': 'Listing Tags',
  'Fully Diluted Market Cap': 'Fully Diluted Market Cap',
  'The market cap if the max supply was in circulation':
    'The market cap if the max supply was in circulation',
  'Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply. if max supply and total supply are infinite or not available, fully-diluted market cap shows - -':
    'Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply. if max supply and total supply are infinite or not available, fully-diluted market cap shows - -',
  "The total market value of a cryptocurrency's circulating supply.It is analogous to the free - float capitalization in the stock market":
    "The total market value of a cryptocurrency's circulating supply.It is analogous to the free - float capitalization in the stock market",
  'Market Cap = Current Price x Circulating Supply':
    'Market Cap = Current Price x Circulating Supply',
  'Market Cap': 'Market Cap',
  'Volume 24h': 'Volume 24h',
  'A measure of how much of a cryptocurrency was traded in the last 24 hours':
    'A measure of how much of a cryptocurrency was traded in the last 24 hours',
  'Circulating Supply': 'Circulating Supply',
  'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market':
    'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market',
  'Max Supply': 'Max Supply',
  'The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market':
    'The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market',
  'If this data has not been submitted by the project or verified by the CMC team, max supply shows - -':
    'If this data has not been submitted by the project or verified by the CMC team, max supply shows - -',
  'Total Supply': 'Total Supply',
  'The amount of coins that have been already created, minus any coins that have been burned. It is analogous to the outstanding shares in the stock market':
    'The amount of coins that have been already created, minus any coins that have been burned. It is analogous to the outstanding shares in the stock market',
  'If this data has not been submitted by the project or verified by the CMC team, total supply shows - -':
    'If this data has not been submitted by the project or verified by the CMC team, total supply shows - -',
  Description: 'Description',
  Return: 'Return',
  'cancel all': 'cancel all',
  'Cancel All Orders': 'Cancel All Orders',
  'Alcor API Docs': 'Alcor API Docs',
  'By default, any newly created market has no fees. Some markets may have fees that are set by the Alcor government. The markets with a commission and its size are shown in the list below. This exchange fee goes towards the Alcor development fund':
    'By default, any newly created market has no fees. Some markets may have fees that are set by the Alcor government. The markets with a commission and its size are shown in the list below. This exchange fee goes towards the Alcor development fund',
  'Min Mint': 'Min Mint',
  'Max Mint': 'Max Mint',
  'Only Duplicates': 'Only Duplicates',
  'Only backed NFTs': 'Only backed NFTs',
  'Swap A To B': 'Swap {A} To {B}'
}
