import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_3dcd155e from 'nuxt_plugin_sentryserver_3dcd155e' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_4fd8b854 from 'nuxt_plugin_sentryclient_4fd8b854' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_image_6a368c95 from 'nuxt_plugin_image_6a368c95' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_1f5abb42 from 'nuxt_plugin_cookieuniversalnuxt_1f5abb42' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_54ee743e from 'nuxt_plugin_pluginutils_54ee743e' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_4c6f5eec from 'nuxt_plugin_pluginrouting_4c6f5eec' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7913d403 from 'nuxt_plugin_pluginmain_7913d403' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_vuescrollto_664cd5a2 from 'nuxt_plugin_vuescrollto_664cd5a2' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_plugin_7b9a361d from 'nuxt_plugin_plugin_7b9a361d' // Source: ./nuxt-highcharts/plugin.js (mode: 'all')
import nuxt_plugin_axios_7f2f8f9a from 'nuxt_plugin_axios_7f2f8f9a' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_5ff2ed25 from 'nuxt_plugin_deviceplugin_5ff2ed25' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_pluginserver_57f7bc62 from 'nuxt_plugin_pluginserver_57f7bc62' // Source: ./color-mode/plugin.server.js (mode: 'server')
import nuxt_plugin_pluginclient_1b836a4c from 'nuxt_plugin_pluginclient_1b836a4c' // Source: ./color-mode/plugin.client.js (mode: 'client')
import nuxt_plugin_elementui_a6a1b20a from 'nuxt_plugin_elementui_a6a1b20a' // Source: ../plugins/element-ui (mode: 'all')
import nuxt_plugin_mixins_5013a1f0 from 'nuxt_plugin_mixins_5013a1f0' // Source: ../plugins/mixins (mode: 'all')
import nuxt_plugin_filters_98405076 from 'nuxt_plugin_filters_98405076' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_global_6441a2ce from 'nuxt_plugin_global_6441a2ce' // Source: ../plugins/global (mode: 'all')
import nuxt_plugin_vClickOutside_3a1129fa from 'nuxt_plugin_vClickOutside_3a1129fa' // Source: ../plugins/vClickOutside.js (mode: 'all')
import nuxt_plugin_muttedDirective_e05448b6 from 'nuxt_plugin_muttedDirective_e05448b6' // Source: ../plugins/muttedDirective.js (mode: 'all')
import nuxt_plugin_routerSync_1c551386 from 'nuxt_plugin_routerSync_1c551386' // Source: ../plugins/routerSync.js (mode: 'all')
import nuxt_plugin_analytics_2e48c327 from 'nuxt_plugin_analytics_2e48c327' // Source: ../plugins/analytics.js (mode: 'all')
import nuxt_plugin_TVChart_4a2a0c1e from 'nuxt_plugin_TVChart_4a2a0c1e' // Source: ../plugins/TVChart.js (mode: 'client')
import nuxt_plugin_apiInstance_7246efbe from 'nuxt_plugin_apiInstance_7246efbe' // Source: ../plugins/apiInstance.js (mode: 'client')
import nuxt_plugin_virtualScroller_1b07d168 from 'nuxt_plugin_virtualScroller_1b07d168' // Source: ../plugins/virtualScroller.js (mode: 'client')
import nuxt_plugin_infinite_247933a6 from 'nuxt_plugin_infinite_247933a6' // Source: ../plugins/infinite.js (mode: 'client')
import nuxt_plugin_startapp_698ab1e2 from 'nuxt_plugin_startapp_698ab1e2' // Source: ../plugins/startapp.js (mode: 'client')
import nuxt_plugin_localStorage_830ec59e from 'nuxt_plugin_localStorage_830ec59e' // Source: ../plugins/localStorage.js (mode: 'client')
import nuxt_plugin_vueapexchart_0ea3e8d8 from 'nuxt_plugin_vueapexchart_0ea3e8d8' // Source: ../plugins/vue-apexchart.js (mode: 'client')
import nuxt_plugin_vuegrid_4a67b318 from 'nuxt_plugin_vuegrid_4a67b318' // Source: ../plugins/vue-grid.js (mode: 'client')
import nuxt_plugin_mojs_709fe199 from 'nuxt_plugin_mojs_709fe199' // Source: ../plugins/mo-js.js (mode: 'client')
import nuxt_plugin_intercom_3315cb7c from 'nuxt_plugin_intercom_3315cb7c' // Source: ../plugins/intercom.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: function() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    const desc = `Alcor Exchange ${this.$t('META_DESCRIPTION')}`
    const title = `Alcor Exchange | ${this.$t('META_TITLE')}`

    return {
      title,
      htmlAttrs: { ...i18nHead.htmlAttrs },
      meta: [
        { charset: 'utf-8' },
        { hid: 'description', name: 'description', content: desc },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'msapplication-TileColor', content: '#da532c' },
        {
          hid: 'og:image',
          name: 'og:image',
          content: '/android-chrome-512x512.png'
        },
        ...i18nHead.meta
      ],
      link: [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/apple-touch-icon.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicon-32x32.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicon-16x16.png'
        },
        { rel: 'manifest', href: '/site.webmanifest' },
        { rel: 'mask-icon', color: '#5bbad5', href: '/safari-pinned-tab.svg' },
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
        ...i18nHead.link
      ]
    }
  },

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_3dcd155e === 'function') {
    await nuxt_plugin_sentryserver_3dcd155e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_4fd8b854 === 'function') {
    await nuxt_plugin_sentryclient_4fd8b854(app.context, inject)
  }

  if (typeof nuxt_plugin_image_6a368c95 === 'function') {
    await nuxt_plugin_image_6a368c95(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_1f5abb42 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_1f5abb42(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_54ee743e === 'function') {
    await nuxt_plugin_pluginutils_54ee743e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_4c6f5eec === 'function') {
    await nuxt_plugin_pluginrouting_4c6f5eec(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7913d403 === 'function') {
    await nuxt_plugin_pluginmain_7913d403(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_664cd5a2 === 'function') {
    await nuxt_plugin_vuescrollto_664cd5a2(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_7b9a361d === 'function') {
    await nuxt_plugin_plugin_7b9a361d(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_7f2f8f9a === 'function') {
    await nuxt_plugin_axios_7f2f8f9a(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_5ff2ed25 === 'function') {
    await nuxt_plugin_deviceplugin_5ff2ed25(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_57f7bc62 === 'function') {
    await nuxt_plugin_pluginserver_57f7bc62(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_1b836a4c === 'function') {
    await nuxt_plugin_pluginclient_1b836a4c(app.context, inject)
  }

  if (typeof nuxt_plugin_elementui_a6a1b20a === 'function') {
    await nuxt_plugin_elementui_a6a1b20a(app.context, inject)
  }

  if (typeof nuxt_plugin_mixins_5013a1f0 === 'function') {
    await nuxt_plugin_mixins_5013a1f0(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_98405076 === 'function') {
    await nuxt_plugin_filters_98405076(app.context, inject)
  }

  if (typeof nuxt_plugin_global_6441a2ce === 'function') {
    await nuxt_plugin_global_6441a2ce(app.context, inject)
  }

  if (typeof nuxt_plugin_vClickOutside_3a1129fa === 'function') {
    await nuxt_plugin_vClickOutside_3a1129fa(app.context, inject)
  }

  if (typeof nuxt_plugin_muttedDirective_e05448b6 === 'function') {
    await nuxt_plugin_muttedDirective_e05448b6(app.context, inject)
  }

  if (typeof nuxt_plugin_routerSync_1c551386 === 'function') {
    await nuxt_plugin_routerSync_1c551386(app.context, inject)
  }

  if (typeof nuxt_plugin_analytics_2e48c327 === 'function') {
    await nuxt_plugin_analytics_2e48c327(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_TVChart_4a2a0c1e === 'function') {
    await nuxt_plugin_TVChart_4a2a0c1e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_apiInstance_7246efbe === 'function') {
    await nuxt_plugin_apiInstance_7246efbe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_virtualScroller_1b07d168 === 'function') {
    await nuxt_plugin_virtualScroller_1b07d168(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infinite_247933a6 === 'function') {
    await nuxt_plugin_infinite_247933a6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_startapp_698ab1e2 === 'function') {
    await nuxt_plugin_startapp_698ab1e2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localStorage_830ec59e === 'function') {
    await nuxt_plugin_localStorage_830ec59e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueapexchart_0ea3e8d8 === 'function') {
    await nuxt_plugin_vueapexchart_0ea3e8d8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuegrid_4a67b318 === 'function') {
    await nuxt_plugin_vuegrid_4a67b318(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mojs_709fe199 === 'function') {
    await nuxt_plugin_mojs_709fe199(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_intercom_3315cb7c === 'function') {
    await nuxt_plugin_intercom_3315cb7c(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
