export default {
  META_DESCRIPTION: 'Ang Alcor Exchange ay ang Swiss knife para sa desentralisadong pananalapi! Mga Liquidity Pool na nakabatay sa ani | Limitahan ang Trading | NFT Market at marami pang iba!',
  META_TITLE: 'EOS walang tiwala sa DEX.',
  META_NFT_MARKET_TITLE: 'Alcor NFT Market | Walang tiwala sa NFT market sa WAX chain',
  META_NFT_MARKET_DESCRIPTION: 'Atomic, walang bayad, NFT marketplace.',
  HERO_DESCRIPTION: 'Ang Alcor ay isang lego ng desentralisadong pananalapi na binuo sa multi-chain, at isang provider ng mga solusyon sa isang tap.',
  HERO_TITLE: 'Lahat sa isang DEX',
  START_TRAIDING_BTN: 'Simulan ang pag-trade',
  READ_DOCS_BTN: 'Basahin ang mga docs',
  ONCHAIN_TOOLS_TITLE: 'Mga On-Chain Tools',
  'AMM Swap': 'AMM Swap',
  'Spot Trading': 'Spot Trading',
  Earn: 'Kumita',
  NFT: 'NFT',
  'With Pair Creation': 'Gamit ang Pair Creation',
  'Limit & Market': 'Limitasyon at Market',
  'Providing Liquidity': 'Pagbibigay ng Liquidity',
  'Multiple Protocols': 'Maramihang Protocols',
  RECOMMENDED_MARKETS_TITLE: 'Mga Inirerekomendang Merkado',
  SUPPORTED_UPCOMING_CHAINS_TITLE: 'Mga Sinusuportahan at Paparating na Chains',
  BUILDINGGLOBALCOMMUNITY: 'Pagbuo ng isang pandaigdigang komunidad.',
  LEARNMORE: 'Matuto nang higit pa tungkol sa Alcor, makipag- chat sa komunidad, at makakuha ng mga anunsyo nang mas mabilis kaysa sinuman.',
  TRAIDING_TALKS: 'Mga Usapang Suporta at Pangkalakalan',
  REVIEW_NEWS: 'Mga Review at Balita',
  CODE_CONTIBUTE: 'Code at Kontribusyon',
  ANOUNCEMENT: 'Mga anunsyo',
  PARTNERS_PROVIDERS: 'Mga Kasosyo at API Providers',
  'at marami pa...': 'at marami pa...',
  Fav: 'Fav',
  All: 'Lahat',
  USDT: 'USDT',
  'Cross-Chain': 'Cross-Chain',
  'Search market': 'Hanapin ang market',
  'Open new market': 'Mag-bukas ng bagong market',
  Pair: 'Pares',
  'Last price': 'Huling presyo',
  '24H Vol.': '24H Vol.',
  '24H': '24H',
  '7D Volume': '7D Volume',
  '7D Change': '7D Change',
  'The OTC contract was changed(updated)!': 'Ang kontrata ng OTC ay binago(updated)!',
  Orders: 'Mga Orders',
  Order: 'Umorder',
  Owner: 'May-ari',
  Sell: 'Ibenta',
  Buy: 'Bumili',
  'Old orders can be found and canceled(recommended) here:': 'Ang mga lumang order ay matatagpuan at kanselahin (inirerekomenda) dito:',
  token: 'token',
  ID: 'ID',
  Price: 'Presyo',
  History: 'Historya',
  'Rules & Information': 'Mga Panuntunan at Impormasyon',
  Properties: 'Pag-aari:',
  RULES_INFO_2: 'Ang lahat ng lohika ng pag-iimbak at pagpapalitan ng order ay nagaganap sa kontrata, nang walang anumang karagdagang sentralisadong solusyon.',
  RULES_INFO_3: 'awtomatikong gumagana ang exchange, nang walang posibilidad na maimpluwensyahan ng mga ikatlong partido ang gawain ng kontrata.',
  RULES_INFO_4: 'Ang application na ito ay gumagana nang walang sentralisadong back-end at gumagamit lamang ng pampublikong EOS node.',
  RULES_INFO_5: 'Ang bawat palitan ay sinisingil ng komisyon na 0.25% para sa parehong mga token kung ang halaga ng transaksyon ay sapat. Kung hindi, para sa maliliit na halaga, walang komisyon na sisingilin.',
  'Roadmap:': 'Roadmap:',
  RULES_INFO_6: 'Pandaigdigang muling pagdidisenyo ng application.',
  RULES_INFO_7: 'Ang web application ay mai-publish sa open source. At kontrata mamaya.',
  RULES_INFO_8: 'Pagbuo ng mga karagdagang serbisyo para sa madaling paghahanap, pag - uuri at paggawa ng mga order.',
  'Audit:': 'Pag-audit:',
  'Exchange contract:': 'Kontrata ng Exchange:',
  'Comission account:': 'Account ng Komisyon:',
  RULES_INFO_9: 'Mga kaibigan at kasosyo ng proyekto. Sa pamamagitan ng anumang pakikipagtulungan maaari mong ipadala ang iyong mga mungkahi sa telegram chat!',
  RULES_INFO_10: 'Isang desentralisadong EOS account exchange.',
  'created by': 'ginawa ng',
  'not found or finished': 'hindi nahanap o natapos',
  'Connect Wallet': 'Ikonekta ang Wallet',
  NewOrder: 'Bagong Order',
  Authors: 'Mga may-akda',
  Categories: 'Mga kategorya',
  'Create NFT token': 'Lumikha ng NFT token',
  'NFT Wallet': 'Wallet ng NFT',
  NFT_ALERT: 'Kahit sino ay maaaring gumawa ng SimpleAssets NFTs at malayang pumili ng mga attributes gaya ng pangalan at larawan, kabilang ang mga pekeng bersyon ng mga kasalukuyang NFTs o ninakaw na intelektwal na ari - arian.Bago bumili ng NFT, palaging gawin ang iyong sariling pagsasaliksik tungkol sa koleksyon at i - double check ang pangalan ng koleksyon upang matiyak na bibili ka ng mga tunay na NFTs.',
  'Immutable data:': 'Hindi nababagong data:',
  Category: 'Mga kategorya',
  'Hide small balances': 'Itago ang maliliit na balanse',
  'Search name or paste address': 'Hanapin ang pangalan o i-paste ang address',
  'Portfolio value': 'Halaga ng portfolio',
  'Active positions': 'Mga aktibong posisyon',
  'Available funds': 'Mga pondong maaaring gamitin',
  'Staking rewards': 'Mga Rewards sa Staking',
  'LP rewards': 'Mga rewards sa LP',
  Tokens: 'Mga Tokens',
  "NFT's": "Mga NFT's",
  'Open Orders': 'Mga bukas na Orders',
  'Liquidity Pools': 'Mga Pools ng Liquidity',
  Resources: 'Mga Resources',
  Asset: 'Mga Asset',
  'Hide small balancess': 'Itago ang mga maliliit na balanse',
  Total: 'Kabuuan',
  Available: 'Available',
  'In Order': 'In Order',
  'WAX Value': 'Halaga ng WAX',
  Actions: 'Mga aksyon',
  Deposit: 'I-Deposit',
  Withdraw: 'I-Withdraw',
  Pools: 'Mga Pools',
  Trade: 'Mag-Trade',
  Swap: 'Swap',
  liquidity: 'liquidity',
  Liquidity: 'Liquidity',
  Volume: 'Dami',
  '7D': '7D',
  '30D': '30D',
  '300D': '300D',
  'Price Impact': 'Epekto sa Presyo',
  Rate: 'Presyo',
  'Minimum Received': 'Minimum na Natanggap',
  Slippage: 'Slippage',
  'Liquidity Source Fee': 'Fee ng Liquidity Source',
  'Earn On Liquidity': 'Kumita sa Liquidity',
  'Buy (Estimated)': 'Bumili (Estimado)',
  'Transaction Setting': 'Setting ng Transaksyon',
  Auto: 'Awto',
  'Create pool': 'Lumikha ng pool',
  'Liquidity Positions': 'Mga Posisyon ng Liquidity',
  'Pool Share': 'Bahagi ng Pool',
  'Earning (Fees)': 'Mga Earning (Fees)',
  'Hide other pairs': 'Itago ang iba pang mga pares',
  Orderbook: 'Orderbook',
  'Depth Chart': 'Chart ng Depth',
  Markets: 'Markets',
  'Times & Sales': 'Mga Oras at Benta',
  'Open orders': 'Mga Bukas na orders',
  'Trade History': 'History ng Trade',
  Funds: 'Mga Pondo',
  'Limit trade': 'Limitahan ang trade',
  'Market trade': 'Market trade',
  'Your order to:': 'Ang iyong order sa:',
  'At a price of:': 'Sa presyo ng:',
  'Will be': 'Ay magiging',
  'do you wish to proceed?': 'gusto mo bang magpatuloy?',
  Yes: 'Oo',
  No: 'Hindi',
  'Filter by token': 'I - filter ayon sa token',
  'Chart Settings': 'Mga Settings ng Chart',
  'Chart Preview': 'Preview ng Chart',
  'Header Settings': 'Mga Settings ng Header',
  'Chart Orders': 'Pagkakasunud-sunod ng Chart',
  SETTING_MODAL_TEX1: 'Nagbibigay - daan sa iyo ang Order Interactivity na ilipat o kanselahin ang mga order nang direkta mula sa chart, i - drag lang ang label para ilipat ang order o i - click ang "X" na button para kanselahin ang order',
  'Reset to Default': 'I-reset sa Default',
  'Change 24H': 'Pagbabago 24H',
  'Volume 24H': 'Dami 24H',
  '24H High': '24H Taas',
  '24H Low': '24H Baba',
  'Volume 24H USD': 'Dami 24H USD',
  'Weekly Volume (WAX,USD) ': 'Lingguhang Dami(WAX,USD)',
  'Show open orders': 'Ipakita ang mga bukas na orders',
  'Show labels': 'Ipakita ang mga labels',
  'Chart order interactivity': 'Pagkakasunud-sunod na Interaktibidad ng Chart',
  'Chart Executions': 'Mga Executions sa Chart',
  'Show trade executions': 'Ipakita ang mga Executions sa Trade',
  'Show tradee xecution amount': 'Ipakita ang halaga ng trade execution',
  TOTAL: 'KABUUAN',
  AMOUNT: 'HALAGA',
  PRICE: 'PRESYO',
  '24H USD': '24H USD',
  'Weekly Volume (WAX / USD)': 'Lingguhang Dami (WAX / USD)',
  'All Time High/Low:': 'Pinaka Mataas/Mababa:',
  Amount: 'Halaga',
  Balance: 'Balanse',
  'Transfer to': 'Ilipat sa',
  WITHDRAW_POPUP_MESSAGE: "Bago mag-transfer sa exchange, kailangan mong i - withdraw ito sa orihinal nitong chain gamit ang BOS IBC Transfer button!",
  Transfer: 'Ilipat',
  to: 'sa',
  Wrapped: 'Wrapped',
  'Pair(a-z)': 'Pares(a-z)',
  'Vol 24h': 'Vol 24H',
  SWAP: 'I-SWAP',
  'Buy at best price': 'Bumili sa pinakamagandang presyo',
  'Buy more order slots': 'Bumili ng maraming order slots',
  'Sell all items for': 'Ibenta ang lahat ng mga item para sa',
  Author: 'May-Akda',
  "Select NFT's": "Pumili ng mga NFT's",
  NEW_ORDER_MESSAGE: "Pinapayagan ka ng form na ito na magbenta ng isa o maramihang NFT's nang sabay-sabay sa nakapirming presyo na",
  "Filter NFT's": "I-Filter ang mga NFT's",
  'Fee Rate': 'Rate ng Bayad',
  'Maker Fee': 'Fee ng Maker',
  'Taker Fee': 'Fee ng Taker',
  Fee: 'Bayad',
  'balance:': 'balanse:',
  Time: 'Oras',
  'No open orders': 'Walang open orders',
  Type: 'Uri',
  Label: 'Label',
  Cancel: 'Kanselahin',
  'Cancel order': 'Kanselahin ang order',
  'Buy for': 'Bumili para sa',
  'Go to main page': 'Pumunta sa pangunahing pahina',
  Manage: 'Pamahalaan',
  Side: 'Side',
  Date: 'Petsa',
  Bid: 'Mag-Bid',
  Ask: 'Magtanong',
  Action: 'Aksyon',
  'Current Orders': 'Mga Kasalukuyang Orders',
  'Total Quote': 'Kabuuang Quote',
  'Total Base': 'Kabuuang Base',
  Earning: 'Kumikita',
  'Earning WAX Value': 'Halaga ng Kinitang WAX',
  fav: 'fav',
  all: 'lahat',
  'My Deposits': 'Aking Mga Deposito',
  'All Type': 'Lahat ng Uri',
  'Liquidity mining': 'Pagmimina sa Liquidity',
  Staking: 'Pag-Stake',
  Partners: 'Mga Partners',
  'System token or USDT is recommended': 'System token or USDT ay inirerekomenda',
  'Auto select': 'Awtomatikong pagpili',
  'Select token for new market': 'Pumili ng token para sa bagong market',
  Manually: 'Mano-mano',
  'Token symbol': 'Simbolo ng token',
  'Token contract': 'Kontrata ng Token',
  'Total Sum': 'Kabuuang Sum',
  'Total Amount': 'Kabuuang Halaga',
  Token: 'Token',
  Quote: 'Quote',
  Seller: 'Nagbebenta',
  Buyer: 'Bumibili',
  buy: 'bumili',
  sell: 'ibenta',
  'Slippage Tolerance': 'Ang Slippage Tolerance',
  'Token amount': 'Halaga ng Token',
  Select: 'Pumili',
  'Sell token': 'Magbenta ng token',
  'Voting For': 'Bumuboto para sa',
  'Proxied Accounts': 'Mga proxy na accounts',
  Account: 'Account',
  Proxies: 'Mga Proxies',
  Stake: 'Mag-Stake',
  'NET Stake Amount': 'Halaga ng stake sa NET',
  'CPU Stake Amount': 'Halaga ng stake sa CPU',
  'NET Unstake Amount': 'Halaga ng Unstake sa NET',
  'CPU Unstake Amount': 'Halaga ng Unstake sa CPU',
  Unstake: 'Mag-Unstake',
  'Crete new pool': 'Gumawa ng bagong pool',
  'To create a pool, select the quote token and provide the initial liquidity ratio': 'Upang lumikha ng pool, piliin ang quote token at ibigay ang paunang ratio ng Liquidity',
  'You are the first liquidity provider. The ratio of tokens you add will set the price of this pool': 'Ikaw ang unang liquidity provider. Ang ratio ng mga tokens na idaragdag mo ay magtatakda ng presyo sa pool na ito',
  'Backed token symbol (Automatically set recommended)': 'Ang simbulo ng Backed token (Inirerekomendang itakda ng awtomatiko)',
  'Pool creation is': 'Ang paggawa ng pool ay',
  FREE: 'LIBRE',
  Create: 'Gumawa',
  'Genesis and Voting Rewards': 'Genesis at mga Voting Rewards',
  'Available Balance': 'Available na Balanse',
  'Refunding CPU': 'Nirerefund na CPU',
  'Refunding NET': 'Nirerefund na NET',
  'Your refund will be available at': 'Magiging available ang iyong refund sa',
  Validators: 'Mga Validators',
  Links: 'Mga Links',
  Location: 'Lokasyon',
  Votes: 'Mga Boto',
  'Total Votes': 'Kabuuang mga Boto',
  'Rewards Per Day': 'Mga Rewards Kada Araw',
  Status: 'Kalagayan',
  Rank: 'Ranggo',
  Guilds: 'Mga Guilds',
  Send: 'Mag-Send',
  'Estimated Get': 'Tantyadong Makukuha',
  'Select amounts': 'Pumili ng halaga',
  'Enter your EOS wallet address': 'Ilagay ang iyong EOS wallet address',
  Next: 'Susunod',
  'EOS ORDERS': 'EOS ORDERS',
  'avg completion time': 'avg na oras ng pagkumpleto',
  '6 Minutes': '6 na Minuto',
  '21% faster': '21% na mas mabilis',
  'than other exchanges': 'kaysa sa ibang palitan',
  'Exchanging 12 ETH to 7234 EOS': 'Pagpapalit ng 12 ETH sa 7234 EOS',
  'Enter Address': 'Ilagay ang Address',
  'Send Coin': 'Mag-Send ng Coin',
  'Some description': 'Ilang paglalarawan',
  'Exchange Status': 'Katayuan ng Palitan',
  "Don't have wallet yet?": "Wala ka pang wallet?",
  Second: 'Ikalawa',
  'Cross-Chain token swap': 'Cross-Chain token swap',
  'No-fee,fully decentralized(Yes.no gateway)': 'Walang bayad, ganap na desentralisado(Oo.walang gateway)',
  'Account name': 'Pangalan ng account',
  'DefiBox Swap': 'DefiBox Swap',
  Evodex: 'Evodex',
  'Swap tokens using Defibox': 'Mag-Swap ng mga tokens gamit ang Defibox',
  'Search pool': 'Maghanap ng pool',
  'NFT creation': 'Paggawa ng NFT',
  'Alpha version': 'Alpha na Bersyon',
  'This form allow you to create an NFT': 'Ang form na ito ay nagpapahintulot sa iyo na lumikha ng isang NFT',
  'You will be the owner of the NFT immediately after creation and will be able to transfer or sell the NFT': 'Ikaw agad ang magiging may-ari ng NFT pagkatapos gawin at magagawa mong ilipat o ibenta ang NFT',
  'Name of the NFT': 'Pangalan ng NFT',
  key: 'key',
  value: 'halaga',
  'Mutable data': 'Nababagong data',
  'Data that you can change(as the author of the NFT': 'Data na maaari mong baguhin (bilang may-akda ng NFT',
  'Create NFT': 'Gumawa ng NFT',
  Add: 'Magdagdag',
  'Search NFT': 'Maghanap ng NFT',
  'Create new order': 'Gumawa ng bagong order',
  'Select token': 'Pumili ng token',
  'Potential SCAM token': 'Potensyal SCAM na token',
  'The DMT token has been hacked. Any movement of the token (including cancellation of the order) takes 99% of the transfer amount. For any questions, please contact the DMT team': 'Ang DMT token ay na - hack.Ang anumang paggalaw ng token(kabilang ang pagkansela ng order) ay tumatagal ng 99 % ng halaga ng paglilipat.Para sa anumang mga katanungan, mangyaring makipag - ugnayan sa DMT team',
  'Cross Chain transfers of BOSIBC tokens are temporary stopped! It is recommended to wait for the news before continuing trading': 'Cross Chain transfers ng BOSIBC tokens ay pansamantalang itinigil! Inirerekomenda na maghintay ng balita bago magpatuloy sa pag-trade',
  BUY: 'BUMILI',
  SELL: 'MAGBENTA',
  view: 'tingnan',
  'Only buy orders': 'Mga Biniling orders lamang',
  'Only sell orders': 'Mga Binentang orders lamang',
  'Total orders': 'Kabuuang mga order',
  'Order slot limit': 'Limitasyon ng Order slot',
  "There is no NFT's yet": "Wala pang mga NFT�s",
  'With Alcor you can trade any EOS.IO tokens for system EOS tokens, atomically, without the participation of third parties! The tokens should comply with the standard eosio.token of the contract': 'Sa Alcor maaari mong ipagpalit ang anumang mga tokens ng EOS.IO para sa mga system EOS tokens, nang awtomiko, at walang paglahok sa mga third party! Ang mga tokens ay dapat sumunod sa karaniwang kontrata ng eosio.token',
  Fully: 'Fully',
  onchain: 'onchain',
  'matching for limit/market trades': 'pagpili ng limit/market trades',
  "All the logic of order storage and matching takes place in the contract's ram, without any additional centralized solutions": "Ang lahat ng lohika sa pag - iimbak at pagtutugma ng order ay nagaganap sa ram ng kontrata, nang walang anumang karagdagang sentralisadong solusyon",
  'This application works without centralized back-end and uses only the public EOS node and public api serivices': 'Gumagana ang application na ito nang walang sentralisadong back-end at gumagamit lamang ng pampublikong EOS node at pampublikong mga serbisyo ng api',
  'No commission at all': 'Walang komisyon',
  'for beta testing time': 'oras para sa beta testing',
  FAQ: 'FAQ',
  'You can add token, options': 'Maaari kang magdagdag ng mga pagpipilian ng token',
  'Add icon to Eos token collection': 'Magdagdag ng icon sa koleksyon ng token ng Eos',
  'All icon on alcor': 'Lahat ng icon sa alcor',
  'name format': 'format ng pangalan',
  'If you cant do any of that': 'Kung hindi mo magawa ang alinman sa mga iyon',
  'send me': 'mag-send sa akin',
  'Exchange contract': 'Kontrata ng Exchange',
  Technologies: 'Mga teknolohiya',
  'Hyperion by': 'Hyperion by',
  'The nice tool to get all actions history': 'Ang magandang tool para makuha ang history ng lahat ng aksyon',
  'All trading graphs and deals history provided by hyperion': 'Ang lahat ng mga trading graph at deals history ay ibinigay ng hyperion',
  'Light API': 'Light API',
  'The nice tool to get token balances for users': 'Ang magandang tool upang makakuha ng mga balanse ng token para sa mga users',
  'Hosted by': 'Pinaghandaan ng',
  'EOS Amsterdam': 'EOS Amsterdam',
  'Is very useful eosio chains explorer': 'Ay napaka - kapaki - pakinabang na eosio chain explorer',
  'It uses for show all deals history and token contracts': 'Ginagamit ito upang ipakita ang lahat ng kasaysayan ng deal at mga kontrata ng token',
  'Add Liquidity': 'Magdagdag ng Liquidity',
  'Select pair': 'Pumili ng pares',
  'Clear All': 'Alisin Lahat',
  'Select Pool': 'Pumili ng Pool',
  'Select a pool type based on your preferred liquidity provider fee': 'Pumili ng uri ng pool ayon sa iyong ginustong bayad sa provider ng liquidity',
  'Set Price Range': 'I-Set ang Price Range',
  price: 'presyo',
  'Select a pool type based on your preferred liquidity provider fee.Your liquidity will only earn fees when the market price of the pair is within your range': 'Pumili ng uri ng pool ayon sa iyong ginustong bayad sa provider ng liquidity. Ang iyong liquidity ay makakakuha lamang ng mga fees kapag ang presyo sa merkado ng pares ay nasa loob ng iyong saklaw',
  'Max Price': 'Pinakamataas na Presyo',
  'Min Price': 'Pinakamababang Presyo',
  'Deposit Amounts': 'Mga Halaga ng Deposito',
  'Submit Test': 'Isumite ang pagsusulit',
  'This is not a verification or request listing form': 'Ito ay hindi isang verification o request listing form',
  'By selecting a token from the list below, you will be offered to sign a transaction that instantly opens a new market that will immediately be ready for placing orders': 'Sa pamamagitan ng pagpili ng isang token mula sa listahan sa ibaba, ikaw ay inaalok na pumirma sa isang transaksyon na agad na magbubukas ng isang bagong merkado na agad na magiging handa para sa paglalagay ng mga order.',
  'This happens on smart contract, without the participation of third parties': 'Nangyayari ito sa smart contract, nang walang paglahok sa mga ikatlong partido',
  'You will buy': 'Bibili ka ng',
  'RAM bytes for new market creation': 'RAM bytes para sa bagong paglikha ng market',
  'BASE TOKEN': 'BASE NA TOKEN',
  'QUOTE TOKEN': 'QUOTE NA TOKEN',
  'Unable to fetch user tokens.. use manually method': 'Hindi makuha ang mga tokens ng user.. gumamit ng manual na paraan',
  'eosio.token betdicetoken ridlridlcoin eosiomeetone etc..': 'eosio.token betdicetoken ridlridlcoin eosiomeetone etc..',
  'DICE TRYBE CAT EOS etc..': 'DICE TRYBE CAT EOS etc..',
  'Creation new market fee is': 'Paglikha ng bagong market fee ay',
  'Estimated Receive': 'Estimadong Makukuha',
  Invalid: 'Imbalido',
  'Insufficient Funds': 'Hindi Sapat na Pondo',
  "Oops! We can't seem to find the page you are looking for": "Oops! Mukhang hindi namin mahanap ang page na hinahanap mo",
  "Back to Home": "Bumalik sa Home",
  'Participate in Protocol': 'Makilahok sa Protocol',
  'Alcor DAO coming soon': 'Ang Alcor DAO ay malapit ng dumating',
  'Alcor Revenue Distribution': 'Distribusyon ng Alcor Revenue',
  Governance: 'Pamamahala',
  'Vote for Proposals': 'Bumoto para sa Mga Panukala',
  'Release schedule': 'Iskedyul ng pag-release',
  Allocation: 'Alokasyon',
  'The token is on sale soon, wait for updates on': 'Malapit nang ibenta ang token, maghintay para sa mga update sa',
  Logout: 'Mag-Logout',
  Language: 'Wika',
  Layouts: 'Mga Layouts',
  'Layout Modules': 'Layout ng Modules',
  'Return to Default Settings': 'Bumalik sa Default Settings',
  'Current Price': 'Kasalukuyang Presyo',
  Max: 'Max',
  'Search name': 'Hanapin ang Pangalan',
  "Don't have any wallet yet": "Wala pang wallet",
  Get: 'Kumuha',
  'Network Resources': 'Resources ng Network',
  'You are low on CPU! Need more resources': 'Mababa na ang iyong CPU! Kailangan ng higit pang mapagkukunan',
  'You can unstake your': 'Maaari mong i-unstake ang iyong',
  'at any time': 'kahit anong oras',
  'Total Staked': 'Kabuuang Taya',
  "Sell NFT's": "Magbenta ng NFT's",
  items: 'mga items',
  category: 'kategorya',
  'Tokens are locked in the contract until the transaction is completed': 'Ang mga tokens ay naka-lock sa kontrata hanggang sa makumpleto ang transaksyon',
  'You can cancel the transaction at any time and return the tokens': 'Maaari mong kanselahin ang transaksyon anumang oras at ibalik ang mga tokens',
  'Pool creation fee is': 'Ang bayad sa paglikha ng pool ay',
  'Provide liquidity': 'Magbigay ng Liquidity',
  'You can earn money on commissions from exchanges. Be sure to familiarize yourself with the principle of operation of liquidity pools': 'Maaari kang kumita ng pera sa mga komisyon mula sa mga palitan. Tiyaking pamilyar ka sa prinsipyo ng pagpapatakbo ng mga liquidity pools',
  'Liquidity provider fee': 'Fee ng Liquidity provider',
  'Pool size': 'Laki ng Pool',
  'Pool price': 'Presyo ng Pool',
  'Pool token supply': 'Token supply ng Pool',
  'Liquidity token receive': 'Natanggap na Liquidity token',
  Provide: 'Mag-Provide',
  'Quick swap or make money on providing liquidity': 'Mabilis na palitan o kumita ng pera sa pagbibigay ng liquidity',
  'The amount that you give': 'Halaga na iyong binigay',
  Output: 'Output',
  Input: 'Input',
  'The amount that you will receive': 'Halaga na iyong matatanggap',
  'Price for current amount': 'Presyo ng Kasalukuyang Halaga',
  'No pools yet': 'Wala pang pool',
  'Withdraw liquidity': 'Alisin ang liquidity',
  'Backed token balance': 'Balanse ng Backed token',
  'Will receive': 'Ay makakatanggap',
  'Position (LP-T)': 'Posisyon (LP-T)',
  'When the deposit becomes negative, it means that your liquidity has been profitable, and you have withdrawn the deposit and part of dividend': 'Kapag ang deposito ay naging negatibo, nangangahulugan ito na ang iyong liquidity ay kumikita, at ikaw ay nag - withdraw ng deposito at bahagi ng dibidendo',
  'When you receive or transfer LP Tokens, the system will increase or decrease your deposit in proportion according to the total liquidity volume of liquidity pool': 'Kapag nakatanggap ka o naglipat ng LP Tokens, tataas o babawasan ng system ang iyong deposito batay sa proporsyon ayon sa kabuuang dami ng liquidity ng pool',
  'There may be a difference in statistics, for reference only': 'Maaaring may pagkakaiba sa mga istatistika, para sa sanggunian lamang',
  'Select Amount': 'Pumili ng Halaga',
  'Search by name or contract': 'Hanapin ayon sa pangalan o kontrata',
  'Slippage Tolerance %': 'Slippage Tolerance %',
  'Orderbook Preview': 'Prebiyu ng Orderbook',
  'Orderbook Settings': 'Settings ng Orderbook',
  'Orderbook Visualization': 'Visualization ng Orderbook',
  'No asks': 'No asks',
  'Recommended tokens': 'Inirerekomendang mga tokens',
  'Deposit Token': 'I-Deposito ang Token',
  'WAX Account Name': 'Pangalan ng WAX Account',
  'NFT Details': 'Mga detalye ng NFT',
  Done: 'Tapos na',
  "Before transferring to exchange, you have to withdraw it to it's original chain using BOS IBC Transfer button": 'Bago lumipat sa exchange, kailangan mong i - withdraw ito sa orihinal nitong chain gamit ang BOS IBC Transfer button',
  'address..': 'address..',
  Receiver: 'Ang Tatanggap',
  Memo: 'Memo',
  'Withdraw amount': 'Halaga ng i-wiwithdraw',
  address: 'address',
  'Your token will be transferred to': 'Ang iyong token ay ililipat sa',
  Promotions: 'Promotions',
  'Daily Chart': 'Daily Chart',
  '1D Volume': '1D Volume',
  'Token promotion': 'Token promotion',
  OTC: 'OTC',
  Wallet: 'Wallet',
  Docs: 'Docs',
  'Top Volume': 'Top Volume',
  'Top Gainers': 'Top Gainers',
  'New Listings': 'New Listings',
  'No Data': 'No Data',
  'Beware of scammers!': 'Beware of scammers!',
  Staked: 'Staked',
  Pairs: 'Pairs',
  'Last Claim': 'Last Claim',
  'Orderbook settings': 'Orderbook settings',
  'Times and sales settings': 'Times and sales settings',
  'Amount in': 'Amount in',
  Preview: 'Preview',
  'Large trade thresholdA': 'Large trade threshold',
  'Time format': 'Time format',
  'choose time format': 'choose time format',
  Currency: 'Currency',
  Theme: 'Theme',
  'Favorite Top Line': 'Favorite Top Line',
  Chart: 'Chart',
  'Orderbook/Depth Chart': 'Orderbook/Depth Chart',
  'Times and Sales': 'Times and Sales',
  'Horizontal Order Form': 'Horizontal Order Form',
  'Vertical Order Form': 'Vertical Order Form',
  FullScreen: 'FullScreen',
  Advanced: 'Advanced',
  Classic: 'Classic',
  'Select Wallet': 'Select Wallet',
  'Wax Cloud Wallet': 'Wax Cloud Wallet',
  From: 'From',
  'Select Token': 'Select Token',
  'Trading Fees': 'Trading Fees',
  'Alcor has different fees based on the type of transaction you want to do': 'Alcor has different fees based on the type of transaction you want to do',
  'When using the swap there is a .3% liquidity provider fee. This fee gets distributed between those providing liquidity in the pool': 'When using the swap there is a .3% liquidity provider fee. This fee gets distributed between those providing liquidity in the pool',
  'Market Exchange': 'Market Exchange',
  'When placing a market or limit order there is a .2% exchange fee on only 4 markets. Certain markets will not have fees depending on the Alcor governance decision. This exchange fee goes towards the Alcor development fund': 'When placing a market or limit order there is a .2% exchange fee on only 4 markets. Certain markets will not have fees depending on the Alcor governance decision. This exchange fee goes towards the Alcor development fund',
  'OTC Transaction': 'OTC Transaction',
  'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged This fee also goes towards the Alcor development fund': 'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged This fee also goes towards the Alcor development fund',
  'Fees table': 'Fees table',
  'Market Maker': 'Market Maker',
  'Market Taker': 'Market Taker',
  Maker: 'Maker',
  Taker: 'Taker',
  'Token Pair': 'Token Pair',
  'Search name or address': 'Search name or address',
  'Full Name': 'Full Name',
  'Token Fundamentals': 'Token Fundamentals',
  Blockchain: 'Blockchain',
  'Ticker Name': 'Ticker Name',
  Socials: 'Socials',
  'Listing time': 'Listing time',
  Contract: 'Contract',
  Website: 'Website',
  'Source Code': 'Source Code',
  'Listing Tags': 'Listing Tags',
  'Fully Diluted Market Cap': 'Fully Diluted Market Cap',
  'The market cap if the max supply was in circulation': 'The market cap if the max supply was in circulation',
  'Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply. if max supply and total supply are infinite or not available, fully-diluted market cap shows - -': 'Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply. if max supply and total supply are infinite or not available, fully-diluted market cap shows - -',
  "The total market value of a cryptocurrency's circulating supply.It is analogous to the free - float capitalization in the stock market": "The total market value of a cryptocurrency's circulating supply.It is analogous to the free - float capitalization in the stock market",
  'Market Cap = Current Price x Circulating Supply': 'Market Cap = Current Price x Circulating Supply',
  'Market Cap': 'Market Cap',
  'Volume 24h': 'Volume 24h',
  'A measure of how much of a cryptocurrency was traded in the last 24 hours': 'A measure of how much of a cryptocurrency was traded in the last 24 hours',
  'Circulating Supply': 'Circulating Supply',
  'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market': 'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market',
  'Max Supply': 'Max Supply',
  'The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market': 'The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market',
  'If this data has not been submitted by the project or verified by the CMC team, max supply shows - -': 'If this data has not been submitted by the project or verified by the CMC team, max supply shows - -',
  'Total Supply': 'Total Supply',
  'The amount of coins that have been already created, minus any coins that have been burned. It is analogous to the outstanding shares in the stock market': 'The amount of coins that have been already created, minus any coins that have been burned. It is analogous to the outstanding shares in the stock market',
  'If this data has not been submitted by the project or verified by the CMC team, total supply shows - -': 'If this data has not been submitted by the project or verified by the CMC team, total supply shows - -',
  Description: 'Description',
  Return: 'Return',
  'cancel all': 'cancel all',
  'Cancel All Orders': 'Cancel All Orders',
  'Alcor API Docs': 'Alcor API Docs',
  'By default, any newly created market has no fees. Some markets may have fees that are set by the Alcor government. The markets with a commission and its size are shown in the list below. This exchange fee goes towards the Alcor development fund': 'By default, any newly created market has no fees. Some markets may have fees that are set by the Alcor government. The markets with a commission and its size are shown in the list below. This exchange fee goes towards the Alcor development fund',
  'Min Mint': 'Min Mint',
  'Max Mint': 'Max Mint',
  'Only Duplicates': 'Only Duplicates',
  'Only backed NFTs': 'Only backed NFTs',
  'Swap A To B': 'Swap {A} To {B}'
}
