export default {
  META_DESCRIPTION: 'Alcor Exchange — швейцарський ніж для твоїх фінансів! | Твої дивіденди з пулів ліквідності | Лімітна торгівля | NFT-маркет та багато іншого!',
  META_TITLE: 'Надійний EOS DEX.',
  META_NFT_MARKET_TITLE: 'Alcor NFT Маркет | Надійний NFT-маркет блокчейну WAX.',
  META_NFT_MARKET_DESCRIPTION: 'Atomic, нуль комісій, NFT-маркетплейс.',
  HERO_DESCRIPTION: 'Alcor — це конструктор децентралізованих фінансів, заснований на кількох ланцюгах, а також постачальник рішень у один дотик.',
  HERO_TITLE: 'Усе в одному DEX',
  START_TRAIDING_BTN: 'Старт торгів',
  READ_DOCS_BTN: 'Почитати документацію',
  ONCHAIN_TOOLS_TITLE: 'On-Chain інструменти',
  'AMM Swap': 'AMM Swap',
  'Spot Trading': 'Спотова торгівля',
  Earn: 'Заробити',
  NFT: 'NFT',
  'With Pair Creation': 'Зі створенням пари',
  'Limit & Market': 'Ліміт & Ринок',
  'Providing Liquidity': 'Надання ліквідності',
  'Multiple Protocols': 'Декілька протоколів',
  RECOMMENDED_MARKETS_TITLE: 'Рекомендовані ринки',
  SUPPORTED_UPCOMING_CHAINS_TITLE: 'Підтримувані та майбутні блокчейни:',
  BUILDINGGLOBALCOMMUNITY: 'Побудова глобального співтовариства.',
  LEARNMORE: 'Вивчай більше про Alcor, спілкуйся з товариством, отримуй анонси раніше за всіх.',
  TRAIDING_TALKS: 'Підтримка & Розмови про торги',
  REVIEW_NEWS: 'Огляд & Новини',
  CODE_CONTIBUTE: 'Коди & Доповнення',
  ANOUNCEMENT: 'Анонси',
  PARTNERS_PROVIDERS: 'Партнери & API Провайдери',
  'and more...': 'ще...',
  Fav: 'Улюблене',
  All: 'Усе',
  USD: 'USDT',
  'Cross-Chain': 'Cross-Chain',
  'Search market': 'Пошук ринку',
  'Open new market': 'Відкрити новий ринок',
  Pair: 'Пари',
  'Last price': 'Остання ціна',
  '24H Vol.': 'Обсяг за 24год',
  '24H': '24год',
  '7D Volume': 'Обсяг за 7д',
  '7D Change': 'Зміни за 7д',
  'The OTC contract was changed(updated)!': 'OTC контракт був змінений(оновлений)!',
  Orders: 'Ордери',
  Order: 'Ордер',
  Owner: 'Власник',
  Sell: 'Продаж',
  Buy: 'Покупка',
  'Old orders can be found and canceled(recommended) here:': 'Старі ордери можуть бути знайдені та відхилені(рекомендовано) тут:',
  token: 'токен',
  ID: 'ID',
  Price: 'Ціна',
  History: 'Історія',
  'Rules & Information': 'Правила & Інформація',
  RULES_INFO_1: `За допомогою TOKENSWAP ви можете обміняти будь-якиі токени EOS.IO на будь-які інші токени EOS.IO
               без участі третіх осіб! Токени мають відповідати стандартам контракту eosio.token.`,
  Properties: 'Властивості:',
  RULES_INFO_2: 'Уся логіка зберігання та обміну замовлень відбувається в контракті без будь-яких додаткових централізованих рішень.',
  RULES_INFO_3: 'Обмін працює автоматично, без можливості сторонніх осіб впливати на роботу контракту.',
  RULES_INFO_4: 'Цей додаток працює без централізованого серверного блоку та використовує лише загальнодоступний вузол EOS.',
  RULES_INFO_5: 'З кожного обміну стягується комісія в розмірі 0,25% за обидва токени, якщо сума транзакції достатня. В іншому випадку за невеликі суми комісія не стягується.',
  'Roadmap:': 'Дорожня карта:',
  RULES_INFO_6: 'Глобальний редизайн додатку.',
  RULES_INFO_7: 'Веб-додаток буде опубліковано у відкритому коді. А контракт пізніше.',
  RULES_INFO_8: 'Розробка додаткових сервісів для зручного пошуку, сортування та роботи із замовленнями.',
  'Audit:': 'Аудит:',
  'Exchange contract:': 'Контракт обміну:',
  'Comission account:': 'Комісійний рахунок:',
  RULES_INFO_9: 'Друзі та партнери проекту. З приводу будь-якої співпраці ви можете надсилати свої пропозиції в телеграм-чат!',
  RULES_INFO_10: 'Децентралізований обмін EOS',
  'created by': 'створено',
  'not found or finished': 'не знайдено або закінчено',
  'Connect Wallet': 'Під’єднати гаманець',
  NewOrder: 'Новий ордер',
  Authors: 'Автор',
  Categories: 'Категорії',
  'Create NFT token': 'Створити NFT токен',
  'NFT Wallet': 'NFT гаманець',
  NFT_ALERT: `Будь-хто може створювати SimpleAssets NFT і вільно вибирати такі атрибути, як ім’я та зображення, включаючи підроблені версії існуючих NFT або викрадену інтелектуальну власність.
             Перш ніж купувати NFT, завжди досліджуйте колекцію самостійно та перевіряйте назву колекції, щоб переконатися, що ви купуєте справжні NFT.`,
  'Immutable data:': 'Незмінні дані:',
  Category: 'Категорія',
  'Hide small balances': 'Приховати малі баланси',
  'Search name or paste address': 'Пошук імені або вставте адресу',
  'Portfolio value': 'Вартість портфоліо',
  'Active positions': 'Активні позиції',
  'Available funds': 'Доступні кошти',
  'Staking rewards': 'Нагороди за стекінг',
  'LP rewards': 'LP нагороди',
  Tokens: 'Токени',
  "NFT's": "NFT'і",
  'Open Orders': 'Відкриті ордери',
  'Liquidity Pools': 'Пули ліквідності',
  Resources: 'Ресурси',
  Asset: 'Актив',
  'Hide small balancess': 'Приховати малі баланси',
  Total: 'Усього',
  Available: 'Доступно',
  'In Order': 'В ордерах',
  'WAX Value': 'Вартість у WAX',
  Actions: 'Дії',
  Deposit: 'Депозит',
  Withdraw: 'Вивід',
  Pools: 'Пули',
  Trade: 'Торгівля',
  Swap: 'Обмін',
  liquidity: 'ліквідність',
  Liquidity: 'Ліквідність',
  Volume: 'Обсяг',
  '7D': '7д',
  '30D': '30д',
  '300D': '300д',
  'Price Impact': 'Вплив ціни',
  Rate: 'Оцінка',
  'Minimum Received': 'Мінімум отримано',
  Slippage: 'Проковзування',
  'Liquidity Source Fee': 'Коміссія джерела ліквідності',
  'Earn On Liquidity': 'Заробити на ліквідності',
  'Buy (Estimated)': 'Купити (Приблизно)',
  'Transaction Setting': 'Налаштування транзакції',
  Auto: 'Автоматично',
  'Create pool': 'Створити пул',
  'Liquidity Positions': 'Позиції по ліквідностям',
  'Pool Share': 'Спільний пул',
  'Earning (Fees)': 'Заробіток (Комісії)',
  'Hide other pairs': 'Приховати інші пари',
  Orderbook: 'Список ордерів',
  'Depth Chart': 'Діаграма глибини',
  Markets: 'Ринки',
  'Times & Sales': 'Час і Продажі',
  'Open orders': 'Відкриті ордери',
  'Trade History': 'Історія торгів',
  Funds: 'Кошти',
  'Limit trade': 'Обмежена торгівля',
  'Market trade': 'Торгівля за ринком',
  'Your order to:': 'Ваші ордери:',
  'At a price of:': 'За ціною:',
  'Will be': 'Буде',
  'do you wish to proceed?': 'чи бажаєте ви продовжити?',
  Yes: 'Так',
  No: 'Ні',
  'Filter by token': 'Фільтрувати за токеном',
  'Chart Settings': 'Налаштування діаграми',
  'Chart Preview': 'Попередній перегляд графіку',
  'Header Settings': 'Налаштування заголовків',
  'Chart Orders': 'Список ордерів',
  SETTING_MODAL_TEX1: `Інтерактивний формат ордерів дозволяє перемістити або скасувати ордер безпосередньо на графіку,
                    просто перетягніть рівень, щоб перемістити ордер або натисніть на кнопку "X" для відміни ордеру`,
  'Reset to Default': 'Скинути до замовчуваних',
  'Change 24H': 'Зміни за 24год',
  'Volume 24H': 'Обсяг за 24год',
  '24H High': 'Найбільша за 24год',
  '24H Low': 'Найменша за 24год',
  'Volume 24H USD': 'Обсяг за 24год у USD',
  'Weekly Volume (WAX, USD)': 'Тижневий обсяг (WAX, USD)',
  'Show open orders': 'Показати відкриті ордери',
  'Show labels': 'Показати мітки',
  'Chart order interactivity': 'Інтерактивні графіки',
  'Chart Executions': 'Виконання графіку',
  'Show trade executions': 'Показати виконані ордери',
  'Show tradee xecution amount': 'Показати суму виконання торгів',
  TOTAL: 'УСЬОГО',
  AMOUNT: 'ЗНАЧЕННЯ',
  PRICE: 'ЦІНА',
  '24H USD': '24год USD',
  'Weekly Volume (WAX / USD):': 'Тижневий обсяг (WAX / USD):',
  'All Time High/Low:': 'Найбільше/Найнижче за весь час:',
  Amount: 'Значення',
  Balance: 'Баланс',
  'Transfer to': 'Надіслати до',
  WITHDRAW_POPUP_MESSAGE: "Перед тим, як провести обмін, ви повинні вивести його в оригінальну мережу за допомогою кнопки BOS IBC Transfer!",
  Transfer: 'Надіслати',
  to: 'до',
  Wrapped: 'Wrapped',
  'Pair(a-z)': 'Пари(a-z)',
  'Vol 24h': 'Обсяг за 24год',
  SWAP: 'ОБМІН',
  'Buy at best price': 'Купити за найкращу ціну',
  'Buy more order slots': 'BКупити більше позицій для ордерів',
  'Sell all items for': 'Продати усе за',
  Author: 'Автор',
  "Select NFT's": "Обрати NFT'і",
  NEW_ORDER_MESSAGE: "Ця форма дозволяє продавати один або кілька NFT одночасно за фіксованою ціною",
  "Filter NFT's": "NFT фільтр",
  'Fee Rate': 'Ставка комісії',
  'Maker Fee': 'Комісія виробника',
  'Taker Fee': 'Сплата',
  Fee: 'Комісія',
  'balance:': 'баланс:',
  Time: 'Час',
  'No open orders': 'Немає відкритих ордерів',
  Type: 'Тип',
  Label: 'Мітка',
  Cancel: 'Відмінити',
  'Cancel order': 'Відмінити ордер',
  'Buy for': 'Купити за',
  'Go to main page': 'На головну',
  Manage: 'Керувати',
  Side: 'Сторона',
  Date: 'Дата',
  Bid: 'Ставка',
  Ask: 'Замовлення',
  Action: 'Дія',
  'Current Orders': 'Поточні замовлення',
  'Total Quote': 'Обсяг замовлень',
  'Total Base': 'Усього',
  Earning: 'Заробіток',
  'Earning WAX Value': 'Заробіток у WAX',
  fav: 'улюблене',
  all: 'усі',
  'My Deposits': 'Мої депозити',
  'All Type': 'Усі види',
  'Liquidity mining': 'Майнінг ліквідностей',
  Staking: 'Стекінг',
  Partners: 'Партнери',
  'System token or USDT is recommended': 'Рекомендуємо системний токен або USDT',
  'Auto select': 'Автовибір',
  'Select token for new market': 'Оберіть токен для нового маркету',
  Manually: 'Вручну',
  'Token symbol': 'Символ токена',
  'Token contract': 'Токен контракт',
  'Total Sum': 'Загальна сума',
  'Total Amount': 'Загальне значення',
  Token: 'Токен',
  Quote: 'Замовлення',
  Seller: 'Продавець',
  Buyer: 'Покупець',
  buy: 'купити',
  sell: 'продати',
  'Slippage Tolerance': 'Допустиме просковзування',
  'Token amount': 'Значення токену',
  Select: 'Обрати',
  'Sell token': 'Продати токен',
  'Voting For': 'Проголосувати за',
  'Proxied Accounts': 'Проксі-аккаунти',
  Account: 'Аккаунт',
  Proxies: 'Проксі',
  Stake: 'Стекінг',
  'NET Stake Amount': 'Значення NET Стекінгу',
  'CPU Stake Amount': 'Значення CPU Стекінгу',
  'NET Unstake Amount': 'Сума анстекінгу з NET',
  'CPU Unstake Amount': 'Сума анстекінгу з CPU',
  Unstake: 'Анстекінг',
  'Crete new pool': 'Створити новий пул',
  'To create a pool, select the quote token and provide the initial liquidity ratio': 'Щоб створити пул, оберіть обсяг токена та надайте йому початковий коефіцієнт ліквідності',
  'You are the first liquidity provider. The ratio of tokens you add will set the price of this pool': 'Ви перший постачальник ліквідності. Співвідношення токенів, які ви додаєте, встановлюватиме ціну цього пулу',
  'Backed token symbol (Automatically set recommended)': 'Символ повернення токену (Рекомендуємо автоматичний вибір)',
  'Pool creation is': 'Створення пулу є',
  FREE: 'БЕЗКОШТОВНИМ',
  Create: 'Створити',
  'Genesis and Voting Rewards': 'Нагороди за голосування та Genesis',
  'Available Balance': 'Доступний баланс',
  'Refunding CPU': 'Відшкодування з CPU',
  'Refunding NET': 'Відшкодування з NET',
  'Your refund will be available at': 'Ваше відшкодування буде доступне',
  Validators: 'Валідатори',
  Links: 'Посилання',
  Location: 'Місцезнаходження',
  Votes: 'Голоси',
  'Total Votes': 'Усього голосів',
  'Rewards Per Day': 'Нагорода за добу',
  Status: 'Статус',
  Rank: 'Ранг',
  Guilds: 'Гільдії',
  Send: 'Надіслати',
  'Estimated Get': 'Очікуване отримання',
  'Select amounts': 'Оберіть суму',
  'Enter your EOS wallet address': 'Введіть адресу свого гаманця EOS',
  Next: 'Далі',
  'EOS ORDERS': 'EOS ОРДЕРИ',
  'avg completion time': 'середній час завершення',
  '6 Minutes': '6 хвилин',
  '21% faster': '21% швидше',
  'than other exchanges': 'ніж інші обмінники',
  'Exchanging 12 ETH to 7234 EOS': 'Обмін 12 ETH на 7234 EOS',
  'Enter Address': 'Введіть адресу',
  'Send Coin': 'Надіслати монетку',
  'Some description': 'Якийсь опис',
  'Exchange Status': 'Статус обмінк=у',
  "Don't have wallet yet?": "Ще не маєте гаманця?",
  Second: 'Другий',
  'Cross-Chain token swap': 'Кросс-чейн обмін токенів',
  'No-fee, fully decentralized(Yes. no gateway)': 'Без комісій, повна децентралізація',
  'Account name': "Ім'я аккаунту",
  'DefiBox Swap': 'DefiBox Обміни',
  Evodex: 'Evodex',
  'Swap tokens using Defibox': 'Обміняти токени за допомогою Defibox',
  'Search pool': 'Пошук пулу',
  'NFT creation': 'Створити NFT',
  'Alpha version': 'Альфа версія',
  'This form allow you to create an NFT': 'Ця форма дозволяє створити NFT',
  'You will be the owner of the NFT immediately after creation and will be able to transfer or sell the NFT': 'Ви станете власником NFT одразу після створення та зможете передавати або продавати його',
  'Name of the NFT': 'Назва NFT',
  key: 'ключ',
  value: 'значення',
  'Mutable data': 'Мутуючі атрибути',
  'Data that you can change(as the author of the NFT': 'Дані, які Ви можете змінити (як автор NFT',
  'Create NFT': 'Створити NFT',
  Add: 'Додати',
  'Search NFT': 'Пошук NFT',
  'Create new order': 'Створити новий ордер',
  'Select token': 'Обрати токен',
  'Potential SCAM token': 'Потенційний SCAM-токен',
  'The DMT token has been hacked. Any movement of the token (including cancellation of the order) takes 99% of the transfer amount. For any questions, please contact the DMT team': 'Токен DMT було зламано. Будь-який рух токена (включаючи скасування замовлення) займає 99% суми переказу. З будь-яких питань звертайтеся до команди DMT',
  'Cross Chain transfers of BOSIBC tokens are temporary stopped! It is recommended to wait for the news before continuing trading': 'Кросс-чейн перекази токенів BOSIBC тимчасово припинені! Рекомендується дочекатися новин, перш ніж продовжувати торгівлю',
  BUY: 'КУПИТИ',
  SELL: 'ПРОДАТИ',
  view: 'огляд',
  'Only buy orders': 'Лише ордери на покупку',
  'Only sell orders': 'Лише ордери на продаж',
  'Total orders': 'Усі ордери',
  'Order slot limit': 'Ліміт слотів на ордери',
  "There is no NFT's yet": "Тут ще немає NFT",
  'With Alcor you can trade any EOS.IO tokens for system EOS tokens, atomically, without the participation of third parties! The tokens should comply with the standard eosio.token of the contract': 'З Alcor ви можете торгувати будь-якими токенами EOS.IO без участі третіх сторін. Токени мають відповідати стандарту eosio.token контракту.',
  Fully: 'Повністю',
  onchain: 'ончейн',
  'matching for limit/market trades': 'відповідність для лімітних/ринкових угод',
  "All the logic of order storage and matching takes place in the contract's ram, without any additional centralized solutions": "Уся логіка зберігання та узгодження торгів відбувається в оперативній пам’яті контракту без будь-яких додаткових централізованих рішень",
  'This application works without centralized back-end and uses only the public EOS node and public api serivices': 'Цей додаток працює без централізованого серверного модуля та використовує лише загальнодоступний вузол EOS і загальнодоступні служби API',
  'No commission at all': 'Відсутність комісій',
  'for beta testing time': 'на час бета-тесту',
  FAQ: 'FAQ',
  'You can add token, options': 'Ви можете додати токен, параметри',
  'Add icon to Eos token collection': 'Додати логотип колекції до токену Eos',
  'All icon on alcor': 'Усі логтипи на alcor',
  'name format': 'формат імені',
  'If you cant do any of that': 'Якщо ви не можете зробити нічого з цього',
  'send me': 'надішліть мені',
  'Exchange contract': 'Контракт обміну',
  Technologies: 'Технології',
  'Hyperion by': 'Hyperion by',
  'The nice tool to get all actions history': 'Чудовий інструмент для отримання історії всіх дій',
  'All trading graphs and deals history provided by hyperion': 'Графіки торгів та їх історія надані hyperion',
  'Light API': 'Легкий API',
  'The nice tool to get token balances for users': 'Чудовий інструмент для отримання маркерних балансів для користувачів',
  'Hosted by': 'Розміщено',
  'EOS Amsterdam': 'EOS Amsterdam',
  'Is very useful eosio chains explorer': 'Найкорисніший оглядач для eosio chains',
  'It uses for show all deals history and token contracts': 'Він використовується для показу усіх угод і контрактів токенів',
  'Add Liquidity': 'Додати Ліквідність',
  'Select pair': 'Обрати пару',
  'Clear All': 'Очистити ВСЕ',
  'Select Pool': 'Обрати Пул',
  'Select a pool type based on your preferred liquidity provider fee': 'Обрати тип пулу на основі вашої бажаної комісії постачальника ліквідності',
  'Set Price Range': 'Встановити діапазон цін',
  price: 'ціна',
  'Select a pool type based on your preferred liquidity provider fee.Your liquidity will only earn fees when the market price of the pair is within your range': 'Виберіть тип пулу на основі бажаної комісії постачальника ліквідності. Ваша ліквідність приноситиме комісію лише тоді, коли ринкова ціна пари буде в межах вашого діапазону',
  'Max Price': 'Максимальна ціна',
  'Min Price': 'Мінімальна ціна',
  'Deposit Amounts': 'Сума депозиту',
  'Submit Test': 'Надіслати тест',
  'This is not a verification or request listing form': 'Це не форма перевірки або запиту на лістинг',
  'By selecting a token from the list below, you will be offered to sign a transaction that instantly opens a new market that will immediately be ready for placing orders': 'Обравши токен зі списку нижче, вам буде запропоновано підписати транзакцію, яка миттєво відкриває новий ринок, який одразу буде готовий для розміщення замовлень',
  'This happens on smart contract, without the participation of third parties': 'Це відбувається у смарт-контракті без участі третіх сторін',
  'You will buy': 'Ви придбаєте',
  'RAM bytes for new market creation': 'RAM байти для створення нового ринку',
  'BASE TOKEN': 'БАЗОВИЙ ТОКЕН',
  'QUOTE TOKEN': 'ТОКЕН КОТИРУВАННЯ',
  'Unable to fetch user tokens.. use manually method': 'Неможливо отримати токени користувача.. використайте ручний метод',
  'eosio.token betdicetoken ridlridlcoin eosiomeetone etc..': 'eosio.token betdicetoken ridlridlcoin eosiomeetone etc..',
  'DICE TRYBE CAT EOS etc..': 'DICE TRYBE CAT EOS etc..',
  'Creation new market fee is': 'Комісія за створення нового ринку становить',
  'Estimated Receive': 'Очікуване отримання',
  Invalid: 'Недійсний',
  'Insufficient Funds': 'Недостатньо коштів',
  "Oops! We can't seem to find the page you are looking for": "Нам дуже жаль! Здається, ми не можемо знайти сторінку, яку ви шукаєте",
  "Back to Home": "Повернутись на головну",
  'Participate in Protocol': 'Участь у протоколі',
  'Alcor DAO coming soon': 'Alcor DAO дуже скоро',
  'Alcor Revenue Distribution': 'Розподіл доходу Alcor',
  Governance: 'Управління',
  'Vote for Proposals': 'Голосуйте за пропозиції',
  'Release schedule': 'Розклад випуску',
  Allocation: 'Аллокація',
  'The token is on sale soon, wait for updates on': 'Продаж токену відбудеться зовсім скоро! Чекайте на оновлення',
  Logout: 'Вийти',
  Language: 'Мова',
  Layouts: 'Макети',
  'Layout Modules': 'Модулі макетів',
  'Return to Default Settings': 'Повернутися до налаштувань за замовчуванням',
  'Current Price': 'Поточна ціна',
  Max: 'Максимум',
  'Search name': 'Пошук назви',
  "Don't have any wallet yet": "Поки що немає гаманця",
  Get: 'Отримати',
  'Network Resources': 'Ресурси мережі',
  'You are low on CPU! Need more resources': 'У Вас недостатньо ресурсів CPU!',
  'You can unstake your': 'Ви можете анстекнути',
  'at any time': 'у будь-який час',
  'Total Staked': 'Застекано загалом',
  "Sell NFT's": "Продати NFT",
  items: 'інвентар',
  category: 'категорія',
  'Tokens are locked in the contract until the transaction is completed': 'Токени заблоковані в контракті до завершення транзакції',
  'You can cancel the transaction at any time and return the tokens': 'Ви можете скасувати транзакцію в будь-який час і повернути токени',
  'Pool creation fee is': 'Плата за створення пулу становить',
  'Provide liquidity': 'Надати ліквідність',
  'You can earn money on commissions from exchanges. Be sure to familiarize yourself with the principle of operation of liquidity pools': "Ви можете заробляти гроші на комісії з бірж. Обов'язково ознайомтеся з принципом роботи пулів ліквідності",
  'Liquidity provider fee': 'Комісія постачальника ліквідності',
  'Pool size': 'Розмір пулу',
  'Pool price': 'Ціна пулу',
  'Pool token supply': 'Загальний обсяг токенів пулу',
  'Liquidity token receive': 'Отримання токену ліквідності',
  Provide: 'Надати',
  'Quick swap or make money on providing liquidity': 'Швидкий обмін або заробіток на забезпеченні ліквідності',
  'The amount that you give': 'Сума, яку ви даєте',
  Output: 'Вихід',
  Input: 'Вхід',
  'The amount that you will receive': 'Сума, яку ви отримаєте',
  'Price for current amount': 'Ціна для поточного значення',
  'No pools yet': 'Пули відсутні',
  'Withdraw liquidity': 'Забрати ліквідність',
  'Backed token balance': 'Розмір повернення токенів',
  'Will receive': 'Отримає',
  'Position (LP-T)': 'Позиція (LP-T)',
  'When the deposit becomes negative, it means that your liquidity has been profitable, and you have withdrawn the deposit and part of dividend': 'Коли депозит стає від’ємним, це означає, що ваша ліквідність була прибутковою, і ви зняли депозит з частиною дивідендів',
  'When you receive or transfer LP Tokens, the system will increase or decrease your deposit in proportion according to the total liquidity volume of liquidity pool': 'Коли ви отримуєте або передаєте токени ліквідності, система збільшить або зменшить ваш депозит пропорційно відповідно до загального обсягу ліквідності пулу ліквідності',
  'There may be a difference in statistics, for reference only': 'У статистиці можуть бути відмінності',
  'Select Amount': 'Оберість суму',
  'Search by name or contract': 'Пошук за назвою або контрактом',
  'Slippage Tolerance %': 'Допуск до провсковзування %',
  'Orderbook Preview': 'Попередній огляд списку ордерів',
  'Orderbook Settings': 'Налаштування списку ордерів',
  'Orderbook Visualization': 'Візуалізація списку ордерів',
  'No asks': 'Немає запитів',
  'Recommended tokens': 'Рекомендовані токени',
  'Deposit Token': 'Депозит токенів',
  'WAX Account Name': "Ім'я облікового запису WAX",
  'NFT Details': 'Деталі NFT',
  Done: 'Готово',
  "Before transferring to exchange, you have to withdraw it to it's original chain using BOS IBC Transfer button": "Перед тим, як передавати на обмін, ви повинні вилучити його до оригінальної мережі за допомогою кнопки BOS IBC Transfer",
  'address..': 'адреса..',
  Receiver: 'Отримувач',
  Memo: 'Коментар',
  'Withdraw amount': 'Сума зняття',
  address: 'адреса',
  'Your token will be transferred to': 'Ваш токен буде передано до',
  Promotions: 'Promotions',
  'Daily Chart': 'Daily Chart',
  '1D Volume': '1D Volume',
  'Token promotion': 'Token promotion',
  OTC: 'OTC',
  Wallet: 'Wallet',
  Docs: 'Docs',
  'Top Volume': 'Top Volume',
  'Top Gainers': 'Top Gainers',
  'New Listings': 'New Listings',
  'No Data': 'No Data',
  'Beware of scammers!': 'Beware of scammers!',
  Staked: 'Staked',
  Pairs: 'Pairs',
  'Last Claim': 'Last Claim',
  'Orderbook settings': 'Orderbook settings',
  'Times and sales settings': 'Times and sales settings',
  'Amount in': 'Amount in',
  Preview: 'Preview',
  'Large trade thresholdA': 'Large trade threshold',
  'Time format': 'Time format',
  'choose time format': 'choose time format',
  Currency: 'Currency',
  Theme: 'Theme',
  'Favorite Top Line': 'Favorite Top Line',
  Chart: 'Chart',
  'Orderbook/Depth Chart': 'Orderbook/Depth Chart',
  'Times and Sales': 'Times and Sales',
  'Horizontal Order Form': 'Horizontal Order Form',
  'Vertical Order Form': 'Vertical Order Form',
  FullScreen: 'FullScreen',
  Advanced: 'Advanced',
  Classic: 'Classic',
  'Select Wallet': 'Select Wallet',
  'Wax Cloud Wallet': 'Wax Cloud Wallet',
  From: 'From',
  'Select Token': 'Select Token',
  'Trading Fees': 'Trading Fees',
  'Alcor has different fees based on the type of transaction you want to do': 'Alcor has different fees based on the type of transaction you want to do',
  'When using the swap there is a .3% liquidity provider fee. This fee gets distributed between those providing liquidity in the pool': 'When using the swap there is a .3% liquidity provider fee. This fee gets distributed between those providing liquidity in the pool',
  'Market Exchange': 'Market Exchange',
  'When placing a market or limit order there is a .2% exchange fee on only 4 markets. Certain markets will not have fees depending on the Alcor governance decision. This exchange fee goes towards the Alcor development fund': 'When placing a market or limit order there is a .2% exchange fee on only 4 markets. Certain markets will not have fees depending on the Alcor governance decision. This exchange fee goes towards the Alcor development fund',
  'OTC Transaction': 'OTC Transaction',
  'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged This fee also goes towards the Alcor development fund': 'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged This fee also goes towards the Alcor development fund',
  'Fees table': 'Fees table',
  'Market Maker': 'Market Maker',
  'Market Taker': 'Market Taker',
  Maker: 'Maker',
  Taker: 'Taker',
  'Token Pair': 'Token Pair',
  'Search name or address': 'Search name or address',
  'Full Name': 'Full Name',
  'Token Fundamentals': 'Token Fundamentals',
  Blockchain: 'Blockchain',
  'Ticker Name': 'Ticker Name',
  Socials: 'Socials',
  'Listing time': 'Listing time',
  Contract: 'Contract',
  Website: 'Website',
  'Source Code': 'Source Code',
  'Listing Tags': 'Listing Tags',
  'Fully Diluted Market Cap': 'Fully Diluted Market Cap',
  'The market cap if the max supply was in circulation': 'The market cap if the max supply was in circulation',
  'Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply. if max supply and total supply are infinite or not available, fully-diluted market cap shows - -': 'Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply. if max supply and total supply are infinite or not available, fully-diluted market cap shows - -',
  "The total market value of a cryptocurrency's circulating supply.It is analogous to the free - float capitalization in the stock market": "The total market value of a cryptocurrency's circulating supply.It is analogous to the free - float capitalization in the stock market",
  'Market Cap = Current Price x Circulating Supply': 'Market Cap = Current Price x Circulating Supply',
  'Market Cap': 'Market Cap',
  'Volume 24h': 'Volume 24h',
  'A measure of how much of a cryptocurrency was traded in the last 24 hours': 'A measure of how much of a cryptocurrency was traded in the last 24 hours',
  'Circulating Supply': 'Circulating Supply',
  'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market': 'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market',
  'Max Supply': 'Max Supply',
  'The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market': 'The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market',
  'If this data has not been submitted by the project or verified by the CMC team, max supply shows - -': 'If this data has not been submitted by the project or verified by the CMC team, max supply shows - -',
  'Total Supply': 'Total Supply',
  'The amount of coins that have been already created, minus any coins that have been burned. It is analogous to the outstanding shares in the stock market': 'The amount of coins that have been already created, minus any coins that have been burned. It is analogous to the outstanding shares in the stock market',
  'If this data has not been submitted by the project or verified by the CMC team, total supply shows - -': 'If this data has not been submitted by the project or verified by the CMC team, total supply shows - -',
  Description: 'Description',
  Return: 'Return',
  'cancel all': 'cancel all',
  'Cancel All Orders': 'Cancel All Orders',
  'Alcor API Docs': 'Alcor API Docs',
  'By default, any newly created market has no fees. Some markets may have fees that are set by the Alcor government. The markets with a commission and its size are shown in the list below. This exchange fee goes towards the Alcor development fund': 'By default, any newly created market has no fees. Some markets may have fees that are set by the Alcor government. The markets with a commission and its size are shown in the list below. This exchange fee goes towards the Alcor development fund',
  'Min Mint': 'Min Mint',
  'Max Mint': 'Max Mint',
  'Only Duplicates': 'Only Duplicates',
  'Only backed NFTs': 'Only backed NFTs',
  'Swap A To B': 'Swap {A} To {B}'
}
