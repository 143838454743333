<template>
  <svg
    viewBox="0 0 129 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="account-avatar"
  >
    <circle cx="64.5" cy="64" r="64" :fill="background" />
    <path
      opacity="0.95"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M65.1869 21.7032C65.0521 21.4756 64.7885 21.3333 64.5014 21.3333C64.2143 21.3333 63.9507 21.4756 63.8159 21.7032L31.0672 77.0023C31.0199 77.0822 30.9914 77.1674 30.9809 77.2535C30.9772 77.2833 30.9756 77.3134 30.9762 77.3438C30.9779 77.4302 30.9976 77.5143 31.0328 77.5923C31.0783 77.6934 31.1499 77.7842 31.2419 77.8565L63.9597 106.469C64.2612 106.733 64.7416 106.733 65.0431 106.469L97.7579 77.8565C97.7679 77.8486 97.7778 77.8405 97.7873 77.8322C97.8642 77.7654 97.9266 77.6836 97.9681 77.5899C97.9991 77.5205 98.0178 77.4463 98.0225 77.3701L98.0235 77.3486L98.0237 77.3311C98.0237 77.3061 98.0222 77.2811 98.0193 77.2565C98.009 77.1694 97.9804 77.0831 97.9326 77.0023L65.1869 21.7032ZM95.6954 76.1948L76.036 42.995L71.4605 69.8015L95.6954 76.1948ZM74.3326 40.1185L64.5014 23.516L54.6692 40.1185H74.3326ZM52.9643 42.9973L33.3045 76.1947L57.5405 69.8006L52.9643 42.9973ZM34.3761 78.6506L63.3823 104.017L60.0538 84.5216L34.3761 78.6506ZM65.6203 104.017L94.6106 78.6621L68.9511 84.607L68.9393 84.6097L68.9327 84.6111L65.6203 104.017ZM34.6903 77.283L57.778 71.1918L59.7981 83.0237L34.6903 77.283ZM64.4984 69.4187L59.2814 70.7951L64.5013 101.369L69.7196 70.7961L64.4984 69.4187ZM74.7178 41.5134L69.9571 69.4049L64.7281 68.0255C64.6545 68.005 64.5772 67.9941 64.4984 67.9942C64.4195 67.9941 64.3421 68.005 64.2685 68.0255L59.0439 69.404L54.282 41.5134H74.7178ZM69.1887 83.1114L71.223 71.1927L94.3262 77.2874L69.1887 83.1114Z"
      :fill="stroke"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  computed: {
    background() {
      return this.$colorMode.preference === 'dark' ? '#000' : '#dcdfe6'
    },
    stroke() {
      return this.$colorMode.preference === 'dark' ? '#dcdfe6' : '#000'
    }

  }
}
</script>
<style>
.account-avatar {
  border-radius: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
</style>
