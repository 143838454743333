export default {
  META_DESCRIPTION: 'Alcor Exchange is the Order Book DEX | Yield-based Liquidity Pools | Limit Trading | NFT Market and much more!',
  META_TITLE: 'The first self-listing Order Book DEX',
  META_NFT_MARKET_TITLE: 'Alcor NFT Market | Trustless NFT market on WAX chain',
  META_NFT_MARKET_DESCRIPTION: 'Atomic, no fee, NFT marketplace.',
  HERO_DESCRIPTION: 'Alcor is a lego of decentralized finance built on multi-chain, and a provider of solutions in one tap.',
  HERO_TITLE: 'All in one DEX',
  START_TRAIDING_BTN: 'Start trading',
  READ_DOCS_BTN: 'Read docs',
  ONCHAIN_TOOLS_TITLE: 'On-Chain Tools',
  'AMM Swap': 'AMM Swap',
  'Spot Trading': 'Spot Trading',
  Earn: 'Earn',
  NFT: 'NFT',
  'With Pair Creation': 'With Pair Creation',
  'Limit & Market': 'Limit & Market',
  'Providing Liquidity': 'Providing Liquidity',
  'Multiple Protocols': 'Multiple Protocols',
  RECOMMENDED_MARKETS_TITLE: 'Recommended Markets',
  SUPPORTED_UPCOMING_CHAINS_TITLE: 'Supported & Upcoming Chains',
  BUILDINGGLOBALCOMMUNITY: 'Building a global community.',
  LEARNMORE: 'Learn more about Alcor, chat with the community, and get announcements faster than anyone.',
  TRAIDING_TALKS: 'Support & Trading Talks',
  REVIEW_NEWS: 'Reviews & News',
  CODE_CONTIBUTE: 'Code & Contribution',
  ANOUNCEMENT: 'Announcements',
  PARTNERS_PROVIDERS: 'Partners & API Providers',
  'and more...': 'and more...',
  Fav: 'Fav',
  All: 'All',
  USDT: 'USDT',
  'Cross-Chain': 'Cross-Chain',
  'Search market': 'Search market',
  'Open new market': 'Open new market',
  Pair: 'Pair',
  'Last price': 'Last price',
  '24H Vol.': '24H Vol.',
  '24H': '24H',
  '7D Volume': '7D Volume',
  '7D Change': '7D Change',
  'The OTC contract was changed(updated)!': 'The OTC contract was changed(updated)!',
  Orders: 'Orders',
  Order: 'Order',
  Owner: 'Owner',
  Sell: 'Sell',
  Buy: 'Buy',
  'Old orders can be found and canceled(recommended) here:': 'Old orders can be found and canceled(recommended) here:',
  token: 'token',
  ID: 'ID',
  Price: 'Price',
  History: 'History',
  'Rules & Information': 'Rules & Information',
  RULES_INFO_1: `With TOKENSWAP you can exchange any EOS.IO tokens, for any other EOS.IO tokens,
               atomically, without the participation of third parties! The tokens should comply with the
               standard eosio.token of the contract.`,
  Properties: 'Properties:',
  RULES_INFO_2: 'All the logic of order storage and exchange takes place in the contract, without any additional centralized solutions.',
  RULES_INFO_3: 'exchange works automatically, without the possibility of third parties to influence the work of the contract.',
  RULES_INFO_4: 'This application works without centralized back-end and uses only the public EOS node.',
  RULES_INFO_5: 'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged.',
  'Roadmap:': 'Roadmap:',
  RULES_INFO_6: 'Global redesign of the application.',
  RULES_INFO_7: 'The web application will be published in open source. And contract later.',
  RULES_INFO_8: 'Development of additional services for easy search, sorting and working with orders.',
  'Audit:': 'Audit:',
  'Exchange contract:': 'Exchange contract:',
  'Comission account:': 'Comission account:',
  RULES_INFO_9: 'Friends and partners of the project. By any collaborations you can send your suggestions to telegram chat!',
  RULES_INFO_10: 'A decentralized EOS account exchange.',
  'created by': 'created by',
  'not found or finished': 'not found or finished',
  'Connect Wallet': 'Connect Wallet',
  NewOrder: 'New Order',
  Authors: 'Authors',
  Categories: 'Categories',
  'Create NFT token': 'Create NFT token',
  'NFT Wallet': 'NFT Wallet',
  NFT_ALERT: `Anyone can create SimpleAssets NFTs and freely choose attributes such as name and image, including fake versions of existing NFTs or stolen intellectual property.
             Before buying an NFT, always do your own research about the collection and double check the collection name to ensure that you are buying genuine NFTs.`,
  'Immutable data:': 'Immutable data:',
  Category: 'Category',
  'Hide small balances': 'Hide small balances',
  'Search name or paste address': 'Search name or paste address',
  'Portfolio value': 'Portfolio value',
  'Active positions': 'Active positions',
  'Available funds': 'Available funds',
  'Staking rewards': 'Staking rewards',
  'LP rewards': 'LP rewards',
  Tokens: 'Tokens',
  "NFT's": "NFT's",
  'Open Orders': 'Open Orders',
  'Liquidity Pools': 'Liquidity Pools',
  Resources: 'Resources',
  Asset: 'Asset',
  'Hide small balancess': 'Hide small balancess',
  Total: 'Total',
  Available: 'Available',
  'In Order': 'In Order',
  'WAX Value': 'WAX Value',
  Actions: 'Actions',
  Deposit: 'Deposit',
  Withdraw: 'Withdraw',
  Pools: 'Pools',
  Trade: 'Trade',
  Swap: 'Swap',
  liquidity: 'liquidity',
  Liquidity: 'Liquidity',
  Volume: 'Volume',
  '7D': '7D',
  '30D': '30D',
  '300D': '300D',
  'Price Impact': 'Price Impact',
  Rate: 'Rate',
  'Minimum Received': 'Minimum Received',
  Slippage: 'Slippage',
  'Liquidity Source Fee': 'Liquidity Source Fee',
  'Earn On Liquidity': 'Earn On Liquidity',
  'Buy (Estimated)': 'Buy (Estimated)',
  'Transaction Setting': 'Transaction Setting',
  Auto: 'Auto',
  'Create pool': 'Create pool',
  'Liquidity Positions': 'Liquidity Positions',
  'Pool Share': 'Pool Share',
  'Earning (Fees)': 'Earning (Fees)',
  'Hide other pairs': 'Hide other pairs',
  Orderbook: 'Orderbook',
  'Depth Chart': 'Depth Chart',
  Markets: 'Markets',
  'Times & Sales': 'Times & Sales',
  'Open orders': 'Open orders',
  'Trade History': 'Trade History',
  Funds: 'Funds',
  'Limit trade': 'Limit trade',
  'Market trade': 'Market trade',
  'Your order to:': 'Your order to:',
  'At a price of:': 'At a price of:',
  'Will be': 'Will be',
  'do you wish to proceed?': 'do you wish to proceed?',
  Yes: 'Yes',
  No: 'No',
  'Filter by token': 'Filter by token',
  'Chart Settings': 'Chart Settings',
  'Chart Preview': 'Chart Preview',
  'Header Settings': 'Header Settings',
  'Chart Orders': 'Chart Orders',
  SETTING_MODAL_TEX1: `Order Interactivity allows you to move or cancel orders directly from the chart,
                    just drag the lavel to move the order or click the "X" button to cancel the orther`,
  'Reset to Default': 'Reset to Default',
  'Change 24H': 'Change 24H',
  'Volume 24H': 'Volume 24H',
  '24H High': '24H High',
  '24H Low': '24H Low',
  'Volume 24H USD': 'Volume 24H USD',
  'Weekly Volume (WAX, USD)': 'Weekly Volume (WAX, USD)',
  'Show open orders': 'Show open orders',
  'Show labels': 'Show labels',
  'Chart order interactivity': 'Chart order interactivity',
  'Chart Executions': 'Chart Executions',
  'Show trade executions': 'Show trade executions',
  'Show tradee xecution amount': 'Show trade executionamount',
  TOTAL: 'TOTAL',
  AMOUNT: 'AMOUNT',
  PRICE: 'PRICE',
  '24H USD': '24H USD',
  'Weekly Volume (WAX / USD)': 'Weekly Volume (WAX / USD)',
  'All Time High/Low:': 'All Time High/Low:',
  Amount: 'Amount',
  Balance: 'Balance',
  'Transfer to': 'Transfer to',
  WITHDRAW_POPUP_MESSAGE: "Before transferring to exchange, you have to withdraw it to it's original chain using BOS IBC Transfer button!",
  Transfer: 'Transfer',
  to: 'to',
  Wrapped: 'Wrapped',
  'Pair(a-z)': 'Pair(a-z)',
  'Vol 24h': 'Vol 24H',
  SWAP: 'SWAP',
  'Buy at best price': 'Buy at best price',
  'Buy more order slots': 'Buy more order slots',
  'Sell all items for': 'Sell all items for',
  Author: 'Author',
  "Select NFT's": "Select NFT's",
  NEW_ORDER_MESSAGE: "This form allow you to sell one or multiple NFT's at once by fixed price in",
  "Filter NFT's": "Filter NFT's",
  'Fee Rate': 'Fee Rate',
  'Maker Fee': 'Maker Fee',
  'Taker Fee': 'Taker Fee',
  Fee: 'Fee',
  'balance:': 'balance:',
  Time: 'Time',
  'No open orders': 'No open orders',
  Type: 'Type',
  Label: 'Label',
  Cancel: 'Cancel',
  'Cancel order': 'Cancel order',
  'Buy for': 'Buy for',
  'Go to main page': 'Go to main page',
  Manage: 'Manage',
  Side: 'Side',
  Date: 'Date',
  Bid: 'Bid',
  Ask: 'Ask',
  Action: 'Action',
  'Current Orders': 'Curent Orders',
  'Total Quote': 'Total Quote',
  'Total Base': 'Total Base',
  Earning: 'Earning',
  'Earning WAX Value': 'Earning WAX Value',
  fav: 'fav',
  all: 'all',
  'My Deposits': 'My Deposits',
  'All Type': 'All Type',
  'Liquidity mining': 'Liquidity mining',
  Staking: 'Staking',
  Partners: 'Partners',
  'System token or USDT is recommended': 'System token or USDT is recommended',
  'Auto select': 'Auto select',
  'Select token for new market': 'Select token for new market',
  Manually: 'Manually',
  'Token symbol': 'Token symbol',
  'Token contract': 'Token contract',
  'Total Sum': 'Total Sum',
  'Total Amount': 'Total Amount',
  Token: 'Token',
  Quote: 'Quote',
  Seller: 'Seller',
  Buyer: 'Buyer',
  buy: 'buy',
  sell: 'sell',
  'Slippage Tolerance': 'Slippage Tolerance',
  'Token amount': 'Token amount',
  Select: 'Select',
  'Sell token': 'Sell token',
  'Voting For': 'Voting For',
  'Proxied Accounts': 'Proxied Accounts',
  Account: 'Account',
  Proxies: 'Proxies',
  Stake: 'Stake',
  'NET Stake Amount': 'NET Stake Amount',
  'CPU Stake Amount': 'CPU Stake Amount',
  'NET Unstake Amount': 'NET Unstake Amount',
  'CPU Unstake Amount': 'CPU Unstake Amount',
  Unstake: 'Unstake',
  'Crete new pool': 'Create new pool',
  'To create a pool, select the quote token and provide the initial liquidity ratio': 'To create a pool, select the quote token and provide the initial liquidity ratio',
  'You are the first liquidity provider. The ratio of tokens you add will set the price of this pool': 'You are the first liquidity provider. The ratio of tokens you add will set the price of this pool',
  'Backed token symbol (Automatically set recommended)': 'Backed token symbol (Automatically set recommended)',
  'Pool creation is': 'Pool creation is',
  FREE: 'FREE',
  Create: 'Create',
  'Genesis and Voting Rewards': 'Genesis and Voting Rewards',
  'Available Balance': 'Available Balance',
  'Refunding CPU': 'Refunding CPU',
  'Refunding NET': 'Refunding NET',
  'Your refund will be available at': 'Your refund will be available at',
  Validators: 'Validators',
  Links: 'Links',
  Location: 'Location',
  Votes: 'Votes',
  'Total Votes': 'Total Votes',
  'Rewards Per Day': 'Rewards Per Day',
  Status: 'Status',
  Rank: 'Rank',
  Guilds: 'Guilds',
  Send: 'Send',
  'Estimated Get': 'Estimated Get',
  'Select amounts': 'Select amounts',
  'Enter your EOS wallet address': 'Enter your EOS wallet address',
  Next: 'Next',
  'EOS ORDERS': 'EOS ORDERS',
  'avg completion time': 'avg completion time',
  '6 Minutes': '6 Minutes',
  '21% faster': '21% faster',
  'than other exchanges': 'than other exchanges',
  'Exchanging 12 ETH to 7234 EOS': 'Exchanging 12 ETH to 7234 EOS',
  'Enter Address': 'Enter Address',
  'Send Coin': 'Send Coin',
  'Some description': 'Some description',
  'Exchange Status': 'Exchange Status',
  "Don't have wallet yet?": "Don't have wallet yet?",
  Second: 'Second',
  'Cross-Chain token swap': 'Cross-Chain token swap',
  'No-fee, fully decentralized(Yes. no gateway)': 'No-fee, fully decentralized(Yes. no gateway)',
  'Account name': 'Account name',
  'DefiBox Swap': 'DefiBox Swap',
  Evodex: 'Evodex',
  'Swap tokens using Defibox': 'Swap tokens using Defibox',
  'Search pool': 'Search pool',
  'NFT creation': 'NFT creation',
  'Alpha version': 'Alpha version',
  'This form allow you to create an NFT': 'This form allow you to create an NFT',
  'You will be the owner of the NFT immediately after creation and will be able to transfer or sell the NFT': 'You will be the owner of the NFT immediately after creation and will be able to transfer or sell the NFT',
  'Name of the NFT': 'Name of the NFT',
  key: 'key',
  value: 'value',
  'Mutable data': 'Mutable data',
  'Data that you can change(as the author of the NFT': 'Data that you can change(as the author of the NFT',
  'Create NFT': 'Create NFT',
  Add: 'Add',
  'Search NFT': 'Search NFT',
  'Create new order': 'Create new order',
  'Select token': 'Select token',
  'Potential SCAM token': 'Potential SCAM token',
  'The DMT token has been hacked. Any movement of the token (including cancellation of the order) takes 99% of the transfer amount. For any questions, please contact the DMT team': 'The DMT token has been hacked. Any movement of the token (including cancellation of the order) takes 99% of the transfer amount. For any questions, please contact the DMT team',
  'Cross Chain transfers of BOSIBC tokens are temporary stopped! It is recommended to wait for the news before continuing trading': 'Cross Chain transfers of BOSIBC tokens are temporary stopped! It is recommended to wait for the news before continuing trading',
  BUY: 'BUY',
  SELL: 'SELL',
  view: 'view',
  'Only buy orders': 'Only buy orders',
  'Only sell orders': 'Only sell orders',
  'Total orders': 'Total orders',
  'Order slot limit': 'Order slot limit',
  "There is no NFT's yet": "There is no NFT's yet",
  'With Alcor you can trade any EOS.IO tokens for system EOS tokens, atomically, without the participation of third parties! The tokens should comply with the standard eosio.token of the contract': 'With Alcor you can trade any EOS.IO tokens for system EOS tokens, atomically, without the participation of third parties! The tokens should comply with the standard eosio.token of the contract',
  Fully: 'Fully',
  onchain: 'onchain',
  'matching for limit/market trades': 'matching for limit/market trades',
  "All the logic of order storage and matching takes place in the contract's ram, without any additional centralized solutions": "All the logic of order storage and matching takes place in the contract's ram, without any additional centralized solutions",
  'This application works without centralized back-end and uses only the public EOS node and public api serivices': 'This application works without centralized back-end and uses only the public EOS node and public api serivices',
  'No commission at all': 'No commission at all',
  'for beta testing time': 'for beta testing time',
  FAQ: 'FAQ',
  'You can add token, options': 'You can add token, options',
  'Add icon to Eos token collection': 'Add icon to Eos token collection',
  'All icon on alcor': 'All icon on alcor',
  'name format': 'name format',
  'If you cant do any of that': 'If you cant do any of that',
  'send me': 'send me',
  'Exchange contract': 'Exchange contract',
  Technologies: 'Technologies',
  'Hyperion by': 'Hyperion by',
  'The nice tool to get all actions history': 'The nice tool to get all actions history',
  'All trading graphs and deals history provided by hyperion': 'All trading graphs and deals history provided by hyperion',
  'Light API': 'Light API',
  'The nice tool to get token balances for users': 'The nice tool to get token balances for users',
  'Hosted by': 'Hosted by',
  'EOS Amsterdam': 'EOS Amsterdam',
  'Is very useful eosio chains explorer': 'Is very useful eosio chains explorer',
  'It uses for show all deals history and token contracts': 'It uses for show all deals history and token contracts',
  'Add Liquidity': 'Add Liquidity',
  'Select pair': 'Select pair',
  'Clear All': 'Clear All',
  'Select Pool': 'Select Pool',
  'Select a pool type based on your preferred liquidity provider fee': 'Select a pool type based on your preferred liquidity provider fee',
  'Set Price Range': 'Set Price Range',
  price: 'price',
  'Select a pool type based on your preferred liquidity provider fee.Your liquidity will only earn fees when the market price of the pair is within your range': 'Select a pool type based on your preferred liquidity provider fee.Your liquidity will only earn fees when the market price of the pair is within your range',
  'Max Price': 'Max Price',
  'Min Price': 'Min Price',
  'Deposit Amounts': 'Deposit Amounts',
  'Submit Test': 'Submit Test',
  'This is not a verification or request listing form': 'This is not a verification or request listing form',
  'By selecting a token from the list below, you will be offered to sign a transaction that instantly opens a new market that will immediately be ready for placing orders': 'By selecting a token from the list below, you will be offered to sign a transaction that instantly opens a new market that will immediately be ready for placing orders',
  'This happens on smart contract, without the participation of third parties': 'This happens on smart contract, without the participation of third parties',
  'You will buy': 'You will buy',
  'RAM bytes for new market creation': 'RAM bytes for new market creation',
  'BASE TOKEN': 'BASE TOKEN',
  'QUOTE TOKEN': 'QUOTE TOKEN',
  'Unable to fetch user tokens.. use manually method': 'Unable to fetch user tokens.. use manually method',
  'eosio.token betdicetoken ridlridlcoin eosiomeetone etc..': 'eosio.token betdicetoken ridlridlcoin eosiomeetone etc..',
  'DICE TRYBE CAT EOS etc..': 'DICE TRYBE CAT EOS etc..',
  'Creation new market fee is': 'Creation new market fee is',
  'Estimated Receive': 'Estimated Receive',
  Invalid: 'Invalid',
  'Insufficient Funds': 'Insufficient Funds',
  "Oops! We can't seem to find the page you are looking for": "Oops! We can't seem to find the page you are looking for",
  "Back to Home": "Back to Home",
  'Participate in Protocol': 'Participate in Protocol',
  'Alcor DAO coming soon': 'Alcor DAO coming soon',
  'Alcor Revenue Distribution': 'Alcor Revenue Distribution',
  Governance: 'Governance',
  'Vote for Proposals': 'Vote for Proposals',
  'Release schedule': 'Release schedule',
  Allocation: 'Allocation',
  'The token is on sale soon, wait for updates on': 'The token is on sale soon, wait for updates on',
  Logout: 'Logout',
  Language: 'Language',
  Layouts: 'Layouts',
  'Layout Modules': 'Layout Modules',
  'Return to Default Settings': 'Return to Default Settings',
  'Current Price': 'Current Price',
  Max: 'Max',
  'Search name': 'Search name',
  "Don't have any wallet yet": "Don't have any wallet yet",
  Get: 'Get',
  'Network Resources': 'Network Resources',
  'You are low on CPU! Need more resources': 'You are low on CPU! Need more resources',
  'You can unstake your': 'You can unstake your',
  'at any time': 'at any time',
  'Total Staked': 'Total Staked',
  "Sell NFT's": "Sell NFT's",
  items: 'items',
  category: 'category',
  'Tokens are locked in the contract until the transaction is completed': 'Tokens are locked in the contract until the transaction is completed',
  'You can cancel the transaction at any time and return the tokens': 'You can cancel the transaction at any time and return the tokens',
  'Pool creation fee is': 'Pool creation fee is',
  'Provide liquidity': 'Provide liquidity',
  'You can earn money on commissions from exchanges. Be sure to familiarize yourself with the principle of operation of liquidity pools': 'You can earn money on commissions from exchanges. Be sure to familiarize yourself with the principle of operation of liquidity pools',
  'Liquidity provider fee': 'Liquidity provider fee',
  'Pool size': 'Pool size',
  'Pool price': 'Pool price',
  'Pool token supply': 'Pool token supply',
  'Liquidity token receive': 'Liquidity token receive',
  Provide: 'Provide',
  'Quick swap or make money on providing liquidity': 'Quick swap or make money on providing liquidity',
  'The amount that you give': 'The amount that you give',
  Output: 'Output',
  Input: 'Input',
  'The amount that you will receive': 'The amount that you will receive',
  'Price for current amount': 'Price for current amount',
  'No pools yet': 'No pools yet',
  'Withdraw liquidity': 'Withdraw liquidity',
  'Backed token balance': 'Backed token balance',
  'Will receive': 'Will receive',
  'Position (LP-T)': 'Position (LP-T)',
  'When the deposit becomes negative, it means that your liquidity has been profitable, and you have withdrawn the deposit and part of dividend': 'When the deposit becomes negative, it means that your liquidity has been profitable, and you have withdrawn the deposit and part of dividend',
  'When you receive or transfer LP Tokens, the system will increase or decrease your deposit in proportion according to the total liquidity volume of liquidity pool': 'When you receive or transfer LP Tokens, the system will increase or decrease your deposit in proportion according to the total liquidity volume of liquidity pool',
  'There may be a difference in statistics, for reference only': 'There may be a difference in statistics, for reference only',
  'Select Amount': 'Select Amount',
  'Search by name or contract': 'Search by name or contract',
  'Slippage Tolerance %': 'Slippage Tolerance %',
  'Orderbook Preview': 'Orderbook Preview',
  'Orderbook Settings': 'Orderbook Settings',
  'Orderbook Visualization': 'Orderbook Visualization',
  'No asks': 'No asks',
  'Recommended tokens': 'Recommended tokens',
  'Deposit Token': 'Deposit Token',
  'WAX Account Name': 'WAX Account Name',
  'NFT Details': 'NFT Details',
  Done: 'Done',
  "Before transferring to exchange, you have to withdraw it to it's original chain using BOS IBC Transfer button": "Before transferring to exchange, you have to withdraw it to it's original chain using BOS IBC Transfer button",
  'address..': 'address..',
  Receiver: 'Receiver',
  Memo: 'Memo',
  'Withdraw amount': 'Withdraw amount',
  address: 'address',
  'Your token will be transferred to': 'Your token will be transferred to',
  Promotions: 'Promotions',
  'Daily Chart': 'Daily Chart',
  '1D Volume': '1D Volume',
  'Token promotion': 'Token promotion',
  OTC: 'OTC',
  Wallet: 'Wallet',
  Docs: 'Docs',
  'Top Volume': 'Top Volume',
  'Top Gainers': 'Top Gainers',
  'New Listings': 'New Listings',
  'No Data': 'No Data',
  'Beware of scammers!': 'Beware of scammers!',
  Staked: 'Staked',
  Pairs: 'Pairs',
  'Last Claim': 'Last Claim',
  'Orderbook settings': 'Orderbook settings',
  'Times and sales settings': 'Times and sales settings',
  'Amount in': 'Amount in',
  Preview: 'Preview',
  'Large trade thresholdA': 'Large trade threshold',
  'Time format': 'Time format',
  'choose time format': 'choose time format',
  Currency: 'Currency',
  Theme: 'Theme',
  'Favorite Top Line': 'Favorite Top Line',
  Chart: 'Chart',
  'Orderbook/Depth Chart': 'Orderbook/Depth Chart',
  'Times and Sales': 'Times and Sales',
  'Horizontal Order Form': 'Horizontal Order Form',
  'Vertical Order Form': 'Vertical Order Form',
  FullScreen: 'FullScreen',
  Advanced: 'Advanced',
  Classic: 'Classic',
  'Select Wallet': 'Select Wallet',
  'Wax Cloud Wallet': 'Wax Cloud Wallet',
  From: 'From',
  'Select Token': 'Select Token',
  'Trading Fees': 'Trading Fees',
  'Alcor has different fees based on the type of transaction you want to do': 'Alcor has different fees based on the type of transaction you want to do',
  'When using the swap there is a .3% liquidity provider fee. This fee gets distributed between those providing liquidity in the pool': 'When using the swap there is a .3% liquidity provider fee. This fee gets distributed between those providing liquidity in the pool',
  'Market Exchange': 'Market Exchange',
  'When placing a market or limit order there is a .2% exchange fee on only 4 markets. Certain markets will not have fees depending on the Alcor governance decision. This exchange fee goes towards the Alcor development fund': 'When placing a market or limit order there is a .2% exchange fee on only 4 markets. Certain markets will not have fees depending on the Alcor governance decision. This exchange fee goes towards the Alcor development fund',
  'OTC Transaction': 'OTC Transaction',
  'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged This fee also goes towards the Alcor development fund': 'Each exchange is charged a commission of 0.25% for both tokens if the transaction amount is sufficient. Otherwise, for small amounts, no commission will be charged This fee also goes towards the Alcor development fund',
  'Fees table': 'Fees table',
  'Market Maker': 'Market Maker',
  'Market Taker': 'Market Taker',
  Maker: 'Maker',
  Taker: 'Taker',
  'Token Pair': 'Token Pair',
  'Search name or address': 'Search name or address',
  'Full Name': 'Full Name',
  'Token Fundamentals': 'Token Fundamentals',
  Blockchain: 'Blockchain',
  'Ticker Name': 'Ticker Name',
  Socials: 'Socials',
  'Listing time': 'Listing time',
  Contract: 'Contract',
  Website: 'Website',
  'Source Code': 'Source Code',
  'Listing Tags': 'Listing Tags',
  'Fully Diluted Market Cap': 'Fully Diluted Market Cap',
  'The market cap if the max supply was in circulation': 'The market cap if the max supply was in circulation',
  'Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply. if max supply and total supply are infinite or not available, fully-diluted market cap shows - -': 'Fully-diluted market cap (FDMC) = price x max supply. If max supply is null, FDMC = price x total supply. if max supply and total supply are infinite or not available, fully-diluted market cap shows - -',
  "The total market value of a cryptocurrency's circulating supply.It is analogous to the free - float capitalization in the stock market": "The total market value of a cryptocurrency's circulating supply.It is analogous to the free - float capitalization in the stock market",
  'Market Cap = Current Price x Circulating Supply': 'Market Cap = Current Price x Circulating Supply',
  'Market Cap': 'Market Cap',
  'Volume 24h': 'Volume 24h',
  'A measure of how much of a cryptocurrency was traded in the last 24 hours': 'A measure of how much of a cryptocurrency was traded in the last 24 hours',
  'Circulating Supply': 'Circulating Supply',
  'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market': 'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market',
  'Max Supply': 'Max Supply',
  'The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market': 'The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market',
  'If this data has not been submitted by the project or verified by the CMC team, max supply shows - -': 'If this data has not been submitted by the project or verified by the CMC team, max supply shows - -',
  'Total Supply': 'Total Supply',
  'The amount of coins that have been already created, minus any coins that have been burned. It is analogous to the outstanding shares in the stock market': 'The amount of coins that have been already created, minus any coins that have been burned. It is analogous to the outstanding shares in the stock market',
  'If this data has not been submitted by the project or verified by the CMC team, total supply shows - -': 'If this data has not been submitted by the project or verified by the CMC team, total supply shows - -',
  Description: 'Description',
  Return: 'Return',
  'cancel all': 'cancel all',
  'Cancel All Orders': 'Cancel All Orders',
  'Alcor API Docs': 'Alcor API Docs',
  'By default, any newly created market has no fees. Some markets may have fees that are set by the Alcor government. The markets with a commission and its size are shown in the list below. This exchange fee goes towards the Alcor development fund': 'By default, any newly created market has no fees. Some markets may have fees that are set by the Alcor government. The markets with a commission and its size are shown in the list below. This exchange fee goes towards the Alcor development fund',
  'Min Mint': 'Min Mint',
  'Max Mint': 'Max Mint',
  'Only Duplicates': 'Only Duplicates',
  'Only backed NFTs': 'Only backed NFTs',
  Details: 'Details',
  'Send Offer': 'Send Offer',
  'Listed Price': 'Listed Price',
  Bids: 'Bids',
  'View Collection': 'View Collection',
  'Sell NFT': 'Sell NFT',
  More: 'More',
  'New Trade': 'New Trade',
  'Create Gift Link': 'Create Gift Link',
  Burn: 'Burn',
  'Best offer by': 'Best offer by',
  'Manage Collection': 'Manage Collection',
  Edit: 'Edit',
  'View Set': 'View Set',
  'Add Friend': 'Add Friend',
  Filter: 'Filter',
  Inventory: 'Inventory',
  Listings: 'Listings',
  Auctions: 'Auctions',
  Sold: 'Sold',
  Bought: 'Bought',
  Sets: 'Sets',
  'You are now trading with': 'You are now trading with',
  Change: 'Change',
  'Your inventory': 'Your inventory',
  'Their inventory': 'Their inventory',
  'Send Trade Offer': 'Send Trade Offer',
  'Sale ID': 'Sale ID',
  'Mint Number': 'Mint Number',
  'Temlate ID': 'Temlate ID',
  Propertise: 'Propertise',
  Burnable: 'Burnable',
  'Transferred (Newest)': 'Transferred (Newest)',
  'Transferred (Oldest)': 'Transferred (Oldest)',
  'Created (Newest)': 'Created (Newest)',
  'Created (Oldest)': 'Created (Oldest)',
  'Mint (Highest)': 'Mint (Highest)',
  'Mint (Lowest)': 'Mint (Lowest)',
  Popularity: 'Popularity',
  'Alphabetical (A-Z)': 'Alphabetical (A-Z)',
  'Alphabetical (Z-A)': 'Alphabetical (Z-A)',
  'Price (Increasing)': 'Price (Increasing)',
  'Price (Decreasing)': 'Price (Decreasing)',
  'End Date (Highest)': 'End Date (Highest)',
  'End Date (Lowest)': 'End Date (Lowest)',
  'Updated (Highest)': 'Updated (Highest)',
  'Updated (Lowest)': 'Updated (Lowest)',
  Transactions: 'Transactions',
  'Seller Page': 'Seller Page',
  'Trade Offer': 'Trade Offer',
  'Remove Friend': 'Remove Friend',
  'Block Account': 'Block Account',
  Visuals: 'Visuals',
  'NFT Name': 'NFT Name',
  'Collection Name': 'Collection Name',
  'Schema Name': 'Schema Name',
  'Backed Tokens': 'Backed Tokens',
  None: 'None',
  'Template ID': 'Template ID',
  'Back tokens': 'Back tokens',
  'List On Market': 'List On Market',
  Attribute: 'Attribute',
  Event: 'Event',
  Data: 'Data',
  'Price Chart': 'Price Chart',
  'Lowest Listing': 'Lowest Listing',
  'Lowest Sale': 'Lowest Sale',
  'Highest Listing': 'Highest Listing',
  'Highest Sale': 'Highest Sale',
  'Recent Listenings': 'Recent Listenings',
  'New NFTs': 'New NFTs',
  'Return - Edit Collection': 'Return - Edit Collection',
  Collection: 'Collection',
  'Edit your collection or add schemas and NFTs': 'Edit your collection or add schemas and NFTs',
  'Buy more': 'Buy more',
  'Display Name': 'Display Name',
  'Website URL': 'Website URL',
  'Collection Fee': 'Collection Fee',
  'This is the official Alcor NFT collection made by our team': 'This is the official Alcor NFT collection made by our team',
  'Alcor Diamonds for all, who knows, maybe they’ll have a use case too one day (;': 'Alcor Diamonds for all, who knows, maybe they’ll have a use case too one day (;',
  'Edit Collection': 'Edit Collection',
  Schemas: 'Schemas',
  'Back NFT': 'Back NFT',
  'Back NFT with tokens': 'Back NFT with tokens',
  'Once you back an NFT with tokens you can only free the tokens by burning the NFT!': 'Once you back an NFT with tokens you can only free the tokens by burning the NFT!',
  SystemBlock: 'SystemBlock',
  Logs: 'Logs',
  Rarity: 'Rarity',
  'The official Alcor DEX NFT collection': 'The official Alcor DEX NFT collection',
  Image: 'Image',
  Explorer: 'Explorer',
  'Accounts can only be searched': 'Accounts can only be searched',
  Bridge: 'Bridge',
  'Search by token name': 'Search by token name',
  'show closed positions': 'show closed positions',
  'Last Genesis Claim': 'Last Genesis Claim',
  'Next Genesis Reward': 'Next Genesis Reward',
  'Next Genesis Claim': 'Next Genesis Claim',
  'Genesis Balance': 'Genesis Balance',
  'Next Voter Rewards': 'Next Voter Rewards',
  'Next Voter Claim': 'Next Voter Claim',
  'Last Voter Claim': 'Last Voter Claim',
  'Claim Genesis': 'Claim Genesis',
  'Claim Both': 'Claim Both',
  'Claim Vote': 'Claim Vote',
  'Receiver of Stake': 'Receiver of Stake',
  Pool: 'Pool',
  'Swap v1': 'Swap v1',
  'P&L': 'P&L',
  'Assets in Position': 'Assets in Position',
  'Assets in Pool': 'Assets in Pool',
  'Unclaimed Fees': 'Unclaimed Fees',
  'Total Value': 'Total Value',
  Range: 'Range',
  'Pool Amount': 'Pool Amount',
  'Claim Fees': 'Claim Fees',
  'Remove Liquidity and Claim Fees': 'Remove Liquidity and Claim Fees',
  Network: 'Network',
  'Token Amount': 'Token Amount',
  'Select Tokens': 'Select Tokens',
  'Enter Amounts': 'Enter Amounts',
  'Enter Amount': 'Enter Amount',
  'Swap A To B': 'Swap {A} To {B}',
  Multiroute: 'Multiroute',
  'Fetching Best price...': 'Fetching Best price...',
  'Minimum Received after slippage': 'Minimum Received after slippage',
  pool: 'pool', // this is lowercase
  Search: 'Search',
  'No tokens found': 'No tokens found.',
  TVL: 'TVL',
  'Your active liquidity positions will appear here.': 'Your active liquidity positions will appear here.',
  'My Positions': 'My Positions',
  'New Position': 'New Position',
  'Your position history will appear here.': 'Your position history will appear here.',
  'Fee Tier': 'Fee Tier',
  Selected: 'Selected',
  'Not created': 'Not created',
  'Best for very high liquidity tokens': 'Best for very high liquidity tokens',
  'Best for most pairs': 'Best for most pairs',
  'Best for low liqudity pairs': 'Best for low liqudity pairs',
  'Invalid range selected. The min price must be lower than the max price.': 'Invalid range selected. The min price must be lower than the max price.',
  'Your position will not earn fees or be used in trades until the market price moves into your range.': 'Your position will not earn fees or be used in trades until the market price moves into your range.',
  'You already have a position with this range. The liquidity in the existing position will be increased.': 'You already have a position with this range. The liquidity in the existing position will be increased.',
  'The market price is outside your specified price range. Single-asset deposit only.': 'The market price is outside your specified price range. Single-asset deposit only.',
  noPoolMessage: 'This pool must be initialized before you can add liquidity. To initialize, select a starting price for the pool. Then, enter your liquidity price range and deposit amount. Pool and position will be created in single transaction.',
  'Estimated Fees (24H) / Fees Share:': 'Estimated Fees (24H) / Fees Share:',
  'Token 1': 'Token 1',
  'Token 2': 'Token 2',
  'Infinity Range': 'Infinity Range',
  'Your position will appear here.': 'Your position will appear here.',
  Loading: 'Loading',
  'Liquidity data not available.': 'Liquidity data not available.',
  'Expected Output': 'Expected Output',
  'Reset State': 'Reset State',
  'Antelope Account Creator': 'Antelope Account Creator',
  'From Greymass Team': 'From Greymass Team',
  'General Chatting': 'General Chatting',
  'Select Network': 'Select Network',
  Analytics: 'Analytics',
}
