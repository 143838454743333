import { render, staticRenderFns } from "./CompactTabs.vue?vue&type=template&id=452f6ccf&scoped=true&lang=pug"
import script from "./CompactTabs.vue?vue&type=script&lang=js"
export * from "./CompactTabs.vue?vue&type=script&lang=js"
import style0 from "./CompactTabs.vue?vue&type=style&index=0&id=452f6ccf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452f6ccf",
  null
  
)

export default component.exports